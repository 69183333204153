<template>
  <div class="container-fluid bg-blue-linear">
    <div class="row h-100 justify-content-center">
      <div class="row mt-4">
        <div class="d-flex justify-content-center align-items-center">
          <img class="w-50 h-50 mt-4" src="~@/assets/images/tvm-assets/1920_homepage_logo.svg" alt="">
        </div>
      </div>
      <div class="row col-8 h-50">
        <div class="col-5 mx-auto d-flex align-items-start justify-content-end">
          <button 
           v-on:click="$router.push( { path:'/booking' } )"
           class="main-btn"
          >
            <div>
              <div class="my-4">
                <img class="icon" src="~@/assets/images/tvm-assets/1920_icon_homepage_booking.svg" alt="">
              </div>
              <div class="my-2 text">
                訂票
              </div>
            </div>
          </button>
        </div>
        <div class="col-5 mx-auto d-flex align-items-start justify-content-start">
          <button 
           v-on:click="$router.push( { path:'/taking' } )"
           class="main-btn"
          >
            <div>
              <div class="my-4">
                <img class="icon" src="~@/assets/images/tvm-assets/1920_icon_homepage_taking.svg" alt="">
              </div>
              <div class="my-2 text">
                取票
              </div>
            </div>
          </button>
        </div>
      </div>
      <div class="row mb-4">
        <div class="d-flex justify-content-end align-items-center mb-4">
          <span class="px-4 text-white fs-45px fw-bold">誠實設計線上訂票</span>
          <!-- <img class="px-4" src="~@/assets/images/tvm-assets/1920_homepage_honest_logo.svg"> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  methods: {
    init() {
    }
  },
  mounted() {
    this.init();
  },
}
</script>
<style scope>

</style>
