<template>
  <div>
    <div class="upper-min-height">
      <Header :title="pageStatus.title" />
      <div class="row my-4 py-4 fs-default">
        <div class="col-10 main-panel mx-auto">
          <div class="text-start text-blue fw-bold">
            選擇去程日期
          </div>
          <div
            class="col-12 d-flex justify-content-start my-4"
            v-if="pageStatus.departure_DateList.length > 0"
          >
            <DateBtn
              v-for="(item, index) in takeArray(
                pageStatus.departure_DateList,
                0,
                4
              )"
              :key="index"
              :date="item.date"
              :isSelecting="item.isSelected"
              :isDeparture="true"
              @select-date="dateSelect"
            />
          </div>
          <div
            class="col-12 d-flex justify-content-start my-4"
            v-if="pageStatus.departure_DateList.length > 4"
          >
            <DateBtn
              v-for="(item, index) in takeArray(
                pageStatus.departure_DateList,
                4,
                8
              )"
              :key="index"
              :date="item.date"
              :isSelecting="item.isSelected"
              :isDeparture="true"
              @select-date="dateSelect"
            />
          </div>
          <div class="text-start text-blue fw-bold">
            選擇回程日期
          </div>
          <div
            class="col-12 d-flex justify-content-start my-4"
            v-if="pageStatus.return_DateList.length > 0"
          >
            <DateBtn
              v-for="(item, index) in takeArray(
                pageStatus.return_DateList,
                0,
                4
              )"
              :key="index"
              :date="item.date"
              :isSelecting="item.isSelected"
              :isDeparture="false"
              @select-date="dateSelect"
            />
          </div>
          <div
            class="col-12 d-flex justify-content-start my-4"
            v-if="pageStatus.return_DateList.length > 4"
          >
            <DateBtn
              v-for="(item, index) in takeArray(
                pageStatus.return_DateList,
                4,
                8
              )"
              :key="index"
              :date="item.date"
              :isSelecting="item.isSelected"
              :isDeparture="false"
              @select-date="dateSelect"
            />
          </div>
        </div>
      </div>
    </div>

    <BottomBar
      :type="'three-btn'"
      :btns="bottomBtns"
      @back-homepage="$router.push('/')"
      @back-step="$emit('back-step', 1)"
      @next-step="nextStep()"
    />
  </div>
</template>
<script>
import Header from "@/components/layout-components/tvm/tvmHeader.vue";
import BottomBar from "@/components/layout-components/tvm/tvmBottomBar.vue";
import DateBtn from "./components/dateBtn.vue";
export default {
  components: {
    Header,
    BottomBar,
    DateBtn,
  },
  watch: {
    "selecting.departureDate": function(val) {
      // console.log(val)
      if (val !== "") {
       this.selecting.returnDate = "";
       let dateOffset = this.dateOffset(val,this.getToday()) ;
       this.pageStatus.return_DateList = this.createDate(val, 7-dateOffset);
      }
    },
  },
  data() {
    return {
      pageStatus: {
        title: "選擇搭乘日期",
        departure_DateList: [],
        return_DateList: [],
      },
      selecting: {
        departureDate: "",
        returnDate: "",
      },
      bottomBtns: [
        {
          name: "取消購票",
          action: "back-homepage",
          isMain: false,
        },
        {
          name: "返回",
          action: "back-step",
          isMain: false,
        },
        {
          name: "下一步",
          action: "next-step",
          isMain: true,
        },
      ],
    };
  },
  methods: {
    init() {
      let today = this.getToday();
      this.pageStatus.departure_DateList = this.createDate(today);
      this.pageStatus.return_DateList = this.createDate(today);
    },
    createDate(date, days=7) {
      let array = [];
      let dateWalker = date;
      for (let i = 0; i < days; i++) {
        // if (i === 0 && date !== this.getToday()) {
        //   array.push({
        //     date: "←更早日期",
        //     isSelected: false,
        //   });
        // } 
        // else if (i === 7) {
        //   array.push({
        //     date: "更晚日期→",
        //     isSelected: false,
        //   });
        // } 
        // else {
          array.push({
            date: dateWalker,
            isSelected: false,
          });
          dateWalker = this.moveOneDay(dateWalker, "+", null);
        // }
      }
      return array;
    },
    takeArray(array, start, end) {
      let result = [];
      for (let i = start; i < end; i++) {
        if ( array[i] === undefined )
          break;
        result.push(array[i]);
      }
      return result;
    },
    dateSelect(emitData) {
      if (emitData.date === "←更早日期") {
        if (emitData.isDeparture) {
          let dateWalker = this.pageStatus.departure_DateList[1].date;
          dateWalker = this.moveOneDay(dateWalker, "-", this.getToday());
          this.pageStatus.departure_DateList = this.createDate(dateWalker);

          this.selecting.departureDate = "";
        } else if (!emitData.isDeparture) {
          let dateWalker = this.pageStatus.return_DateList[1].date;
          dateWalker = this.moveOneDay(
            dateWalker,
            "-",
            this.selecting.departureDate === ""
              ? this.getToday()
              : this.selecting.departureDate
          );
          this.pageStatus.return_DateList = this.createDate(dateWalker);

          this.selecting.returnDate = "";
        }
      } else if (emitData.date === "更晚日期→") {
        if (emitData.isDeparture) {
          let dateWalker =
            this.pageStatus.departure_DateList[0].date === "←更早日期"
              ? this.pageStatus.departure_DateList[1].date
              : this.pageStatus.departure_DateList[0].date;

          dateWalker = this.moveOneDay(dateWalker, "+", null);
          this.pageStatus.departure_DateList = this.createDate(dateWalker);

          this.selecting.departureDate = "";
        } else if (!emitData.isDeparture) {
          let dateWalker =
            this.pageStatus.return_DateList[0].date === "←更早日期"
              ? this.pageStatus.return_DateList[1].date
              : this.pageStatus.return_DateList[0].date;

          dateWalker = this.moveOneDay(dateWalker, "+", null);
          this.pageStatus.return_DateList = this.createDate(dateWalker);

          this.selecting.returnDate = "";
        }
      } else if (emitData.isDeparture) {
        this.select(emitData.date, true);
      } else if (!emitData.isDeparture) {
        this.select(emitData.date, false);
      }
    },
    select(date, isDeparture) {
      if (isDeparture) {
        for (let i = 0; i < this.pageStatus.departure_DateList.length; i++) {
          if (date === this.pageStatus.departure_DateList[i].date) {
            this.pageStatus.departure_DateList[i].isSelected = true;
            this.selecting.departureDate = date;
          } else {
            this.pageStatus.departure_DateList[i].isSelected = false;
          }
        }
      } else if (!isDeparture) {
        for (let i = 0; i < this.pageStatus.return_DateList.length; i++) {
          if (date === this.pageStatus.return_DateList[i].date) {
            this.pageStatus.return_DateList[i].isSelected = true;
            this.selecting.returnDate = date;
          } else {
            this.pageStatus.return_DateList[i].isSelected = false;
          }
        }
      }
    },
    nextStep() {
      if (
        this.selecting.departureDate !== "" &&
        this.selecting.returnDate !== ""
      ) {
        this.$emit("next-step", this.selecting);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scope>

</style>
