<template>
  <div>
    <div class="upper-min-height fw-bold" v-if="is_success">
      <Header :title="''" />
      <div class="row my-4 py-4">
        <div class="col-10 main-panel mx-auto later-pay-css">
          <div class="my-4 py-4" style="height:350px;" >
            <div class="text-center my-4">
              <div>
                  交易成功
              </div> 
            </div>
          </div>
          <div class="py-4">
            <img src="~@/assets/images/tvm-assets/1920_icon_sucess.svg">
          </div>
          <div 
           class="my-4 py-4 d-flex align-items-end justify-content-center fs-default"
          >
            畫面於<span class="text-red mx-4">3</span>秒後自動跳轉
          </div>

        </div>
      </div>
    </div>
    <div class="upper-min-height fw-bold" v-if="!is_success">
      <Header :title="''" />
      <div class="row my-4 py-4">
        <div class="col-10 main-panel mx-auto later-pay-css">
          <div class="my-4 py-4" style="height:350px;">
            <div class="text-center my-4">
              <div>
                  交易失敗
              </div> 
              <div class="py-4">
                  請確認信用卡資訊是否無誤
              </div>
              
            </div>
          </div>
          <div class="py-4">
            <img src="~@/assets/images/tvm-assets/1920_icon_error.svg">
          </div>
          <div 
           class="d-flex align-items-end justify-content-center fs-default py-4"
          >
            畫面於<span class="text-red mx-4">3</span>秒後自動跳轉
          </div>

        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Header from "@/components/layout-components/tvm/tvmHeader.vue";

export default {
  props: {
    is_success: Boolean,
    order_number: String,
    info: String,
  },
  components: {
    Header,
  },
  data() {
    return {
    };
  },
  methods: {
    init() {
      let thisVue = this ;
      if ( this.is_success ) {
        setTimeout( function(){ thisVue.$router.push(
            '/taking?order_number=' + thisVue.order_number 
            + '&info=' + thisVue.info 
        )}, 3000 );
      }
      else {
        setTimeout( function(){ thisVue.$router.push('/')}, 3000 ); 
      }
    },

  },
  mounted() {
    this.init();
  },
};
</script>
<style scope>

.later-pay-css {
  color: #024fa1;
  font-size: 60px;
  line-height: 100px;
  font-weight: bold;
}
</style>
