<template>
  <div>
    <div class="upper-min-height">
      <Header :title="pageStatus.title" />
      <div class="row my-4 py-4">
        <div class="col-10 main-panel mx-auto fs-default">
          <div
            class="text-start text-blue fw-bold d-flex justify-content-between"
          >
            <div>
              請確認所有資訊是否無誤，確認後請按「確認付款」
            </div>
          </div>
          <div class="col-12 d-flex justify-content-between my-4">
            <div class="tvm-panel">
              <div
                class="header d-flex align-items-center justify-content-start"
              >
                <div
                  class="text fw-bold col-11 mx-auto text-start d-flex justify-content-between"
                >
                  <span>信用卡資訊</span>
                </div>
              </div>
              <div class="body py-4 col-11 mx-auto text-start">
                <div class="d-flex justify-content-between">
                  <div class="col-6">
                    <div class="my-2 d-flex">
                      持卡人姓名<span class="text-danger">*</span>
                      <input
                        type="text"
                        class="input-text-width450px mx-2 px-4"
                        :class="{ 'notice' : pageStatus.notices[0] !== undefined && pageStatus.notices[0].desc !== 'success' }"
                        :placeholder="
                          ( pageStatus.notices[0] !== undefined && pageStatus.notices[0].desc !== 'success' ) ? 
                          ( pageStatus.notices[0].desc === 'null' ? '持卡人姓名為必填':'' ) : '請輸入持卡人姓名'
                        "
                        v-model="cardOwner.name"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="my-2 d-flex">
                      <span class="text-nowrap"
                        >持卡人行動電話<span class="text-danger">*</span></span
                      >
                      <input
                        type="text"
                        class="input-text-width450px mx-2 px-4"
                        :class="{ 'notice' : pageStatus.notices[1] !== undefined && pageStatus.notices[1].desc !== 'success' }"
                        :placeholder="
                          ( pageStatus.notices[1] !== undefined && pageStatus.notices[1].desc !== 'success' ) ? 
                          ( pageStatus.notices[1].desc === 'null' ? '行動電話為必填':'行動電話不符合規則' ) : '請輸入行動電話'
                        "
                        v-model="cardOwner.phone"
                        v-on:keyup="cardOwner.phone=cardOwner.phone.replace(/\D/g,'')"
                        v-on:onafterpaste="cardOwner.phone=cardOwner.phone.replace(/\D/g,'')"
                      />
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between my-4 py-4">
                  <div class="col-10">
                    <div class="my-2 d-flex">
                      持卡人Email<span class="text-danger">*</span>
                      <input
                        type="text"
                        class="input-text-width450px mx-4 px-4"
                        :class="{ 'notice' : pageStatus.notices[2] !== undefined && pageStatus.notices[2].desc !== 'success' }"
                        :placeholder="
                          ( pageStatus.notices[2] !== undefined && pageStatus.notices[2].desc !== 'success' ) ? 
                          ( pageStatus.notices[2].desc === 'null' ? 'email為必填':'email不符合規則' ) : '請輸入email'
                        "
                        v-model="cardOwner.email"
                      />
                    </div>
                  </div>
                </div>

                <hr class="my-4" />

                <div class="col-md-12">
                  <div
                    class="d-flex align-items-center justify-content-between col-12 py-4"
                  >
                    <div class="form-group d-flex align-items-center col-6">
                      <label for="number" class="text-nowrap"
                        >信用卡卡號<span class="text-danger">*</span></label
                      >
                      <div
                        class="form-control input-text-width450px mx-2"
                        ref="number"
                      ></div>
                      <!-- <small>(可填入： 4242 4242 4242 4242)</small> -->
                    </div>
                    <div class="form-group d-flex align-items-center col-7">
                      <label class="text-nowrap" for="cardCcv"
                        >檢查碼<span class="text-danger">*</span
                        ><span style="font-size:35px">(背面後三碼)</span></label
                      >
                      <div
                        class="form-control input-text-width450px mx-2"
                        ref="ccv"
                      ></div>
                      <!-- <small>(可填入： 123)</small> -->
                    </div>
                  </div>
                  <div class="d-flex align-items-center my-4 col-12 py-4">
                    <div class="form-group d-flex align-items-center col-6">
                      <label style="width:280px" for="cardExpirationDate"
                        >有效期限<span class="text-danger">*</span></label
                      >
                      <div
                        class="form-control input-text-width450px"
                        ref="expirationDate"
                      ></div>
                      <!-- <small>(可填入： 01/23)</small> -->
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BottomBar
      :type="'two-btn'"
      :btns="bottomBtns"
      @back-homepage="$router.push('/')"
      @pay-confirm="payConfirm()"
    />
  </div>
</template>
<script>
import Header from "@/components/layout-components/tvm/tvmHeader.vue";
import BottomBar from "@/components/layout-components/tvm/tvmBottomBar.vue";
import getEnv from '@/utils/env.js';

export default {
  props: {
    order_number: String,
    info: String,
  },
  components: {
    Header,
    BottomBar,
  },
  data() {
    return {
      cardOwner: {
        name: "",
        phone: "",
        email: "",
      },
      pageStatus: {
        title: "信用卡付款",
        notices: [],
      },
      bottomBtns: [
        {
          name: "取消購票",
          action: "back-homepage",
          isMain: false,
        },
        {
          name: "確認付款",
          action: "pay-confirm",
          isMain: true,
        },
      ],
    };
  },
  computed: {},
  methods: {
    updateStatus(field) {
      switch (field) {
        case 0:
          //欄位已填好，並且沒有問題
          console.log("field is ok");
          break;
        case 1:
          //欄位還沒有填寫
          console.log("field is empty");
          break;
        case 2:
          //欄位有錯誤，此時在 CardView 裡面會用顯示 errorColor
          console.log("field has error");
          break;
        case 3:
          //使用者正在輸入中
          console.log("usertyping");
          break;
        default:
          console.log("error!");
      }
    },
    payConfirm() {
      
      if ( this.validateInput() ) {
        this.onSubmit();
      }
      
    },
    // 觸發去取得狀態
    onSubmit() {
      const tappayStatus = TPDirect.card.getTappayFieldsStatus();
      if (tappayStatus.canGetPrime === false) {
        // can not get prime
        return;
      }

      // Get prime
      TPDirect.card.getPrime((result) => {
        if (result.status !== 0) {
          // get prime error
          console.log(result.msg);
          return;
        }

        let prime = result.card.prime;
        // console.log(prime);
        this.$emit('get-prime-and-cardowner-info', {
          prime: prime,
          cardOwner: {
            name: this.cardOwner.name,
            phone: this.cardOwner.phone,
            email: this.cardOwner.email,
          }
          
        });
        // this.submitPrime(prime);
      });
    },
    async submitPrime(prime) {
      try {
        // 要把得到的Prime Token 送給後端,
        let payReslut = await this.apiPayByPrime(prime);
        if (payReslut.result.status === 0) {
          this.$notify({
            group: "paidSuccess",
            type: "success",
            text: "付款成功, （僅為展示頁面,不會進行出貨）",
          });

          this.createAnOrder();
          this.setStep(3);
        } else {
          this.$notify({
            group: "paidFail",
            type: "warn",
            text: "無法進行付款",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    validateInput() {
      this.pageStatus.notices = [];
      let hasError = false;

      if (this.cardOwner.name === "") {
        this.pageStatus.notices.push({
          title: "name",
          desc: "null",
        });
        hasError = true;
      }
      else {
        this.pageStatus.notices.push({
          title: "name",
          desc: "success",
        });
      }

      if (this.cardOwner.phone === "") {
        this.pageStatus.notices.push({
          title: "phone",
          desc: "null",
        });
        hasError = true;
      } else if (!this.validatePhoneNumber(this.cardOwner.phone)) {
        this.pageStatus.notices.push({
          title: "phone",
          desc: "non-legal",
        });
        this.cardOwner.phone = "";
        hasError = true;
      }
      else {
        this.pageStatus.notices.push({
          title: "phone",
          desc: "success",
        });
      }

      if (this.cardOwner.email === "") {
        this.pageStatus.notices.push({
          title: "email",
          desc: "null",
        });
        hasError = true;
      } else if (!this.validateEmail(this.cardOwner.email)) {
        this.pageStatus.notices.push({
          title: "email",
          desc: "non-legal",
        });
        this.cardOwner.email = "";
        hasError = true;
      }
      else {
        this.pageStatus.notices.push({
          title: "email",
          desc: "success",
        });
      }


      if (hasError) {
        return false;
      } else return true;
    },
    
  },
  mounted() {
    let APP_ID = getEnv('VUE_APP_TAP_PAY_APP_ID');
    let APP_KEY = getEnv('VUE_APP_TAP_PAY_APP_KEY');
    TPDirect.setupSDK(APP_ID, APP_KEY, getEnv('VUE_APP_TAP_PAY_TARGET_ENV'));

    const fields = {
      number: {
        element: this.$refs.number,
        placeholder: "請輸入卡號",
      },
      expirationDate: {
        element: this.$refs.expirationDate,
        placeholder: "MM/YY",
      },
      ccv: {
        element: this.$refs.ccv,
        placeholder: "請輸入檢查碼",
      },
    };

    TPDirect.card.setup({
      fields: fields,
      styles: {
        // Style all elements
        input: {
          color: "gray",
        },
        // Styling ccv field
        "input.ccv": {
          "font-size": "45px",
        },
        // Styling expiration-date field
        "input.expiration-date": {
          "font-size": "45px",
        },
        // Styling card-number field
        "input.card-number": {
          "font-size": "45px",
        },
        // style focus state
        ":focus": {
          // 'color': 'black'
        },
        // style valid state
        ".valid": {
          color: "green",
        },
        // style invalid state
        ".invalid": {
          color: "red",
        },
        // Media queries
        // Note that these apply to the iframe, not the root window.
        "@media screen and (max-width: 400px)": {
          input: {
            color: "orange",
          },
        },
      },
    });

    TPDirect.card.onUpdate((update) => {
      if (update.canGetPrime) {
        //全部欄位皆為正確 可以呼叫 getPrime
        this.disabledBtnPay = false;
      } else {
        this.disabledBtnPay = false;
      }

      this.updateStatus(update.status.number);
      this.updateStatus(update.status.expiry);
      this.updateStatus(update.status.number);
    });
  },
};
</script>
<style scope>
input {
  font-size: 45px;
}
input::placeholder {
  font-size: 45px;
  color: #adadad;
}

select {
  width: 250px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-style: solid;
  height: 90px;
}
select:focus {
  outline: none;
  border-color: #618fca;
  border-width: 2px;
}
</style>
