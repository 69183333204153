<template>
  <div>
    <div class="upper-min-height">
      <Header :title="''" />
      <div class="row my-4 py-4">
        <div class="col-10 main-panel mx-auto later-pay-css">
          <div class="my-4 py-4">
            <div class="text-center my-4">
              <div>
                  列印完成，請於取票口領取船票
              </div> 
              <div class="pb-4">
                  並確認船票數量
              </div>
              <div class="py-4">
                  <img src="~@/assets/images/tvm-assets/1920_icon_sucess.svg">
              </div>
            </div>
          </div>
          <div 
           class="my-4 py-4 d-flex align-items-end justify-content-center fs-default"
           style="height:200px"
          >
            畫面於<span class="text-red mx-4">3</span>秒後回至首頁
          </div>

        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Header from "@/components/layout-components/tvm/tvmHeader.vue";

export default {
  props: {

  },
  components: {
    Header,
  },
  data() {
    return {

    };
  },
  methods: {
    init() {

      let thisVue = this ;
      setTimeout( function(){ thisVue.$router.push('/') }, 3000 );
      
    },


  },
  mounted() {
    this.init();
  },
};
</script>
<style scope>

.later-pay-css {
  color: #024fa1;
  font-size: 60px;
  line-height: 100px;
  font-weight: bold;
}
</style>
