<template>
    <button 
     class="col-2 mx-1 btn-select btn-w-ticketNum"
     :class="{
         'selecting': isSelecting,
         'functional': isFunctionBtn
     }"
     v-on:click="btnClick(num, isAdult)"
    >
        <span class="text">
            {{ num }}
        </span>
    </button>
</template>
<script>
export default {
    props: {
        num: Number,
        isSelecting: Boolean,
        isAdult: Boolean,
    },
    computed: {
        isFunctionBtn() {
            if ( this.num === '←更少' || this.num === '更多→' ) 
              return true;
            else
              return false;
        }
    },
    methods: {
        btnClick(num, isAdult) {
            this.$emit( "select-num" ,{
                num: num,
                isAdult: isAdult,
            });
        },
    },
}
</script>
<style scope>

</style>