<template>
    <div class="bottom-bar row">

      <div 
       v-if="type === 'three-btn'" 
       class="d-flex align-items-center"
      >
        <div class="col-11 mx-auto d-flex">
            <div class="col-2">
                <button 
                  v-if="btns[0]!==undefined"
                  class="btn-inbar" 
                  :class="{ 'main': btns[0].isMain }"
                  v-on:click="$emit(btns[0].action)"
                >
                    <span class="text">{{btns[0].name}}</span> 
                </button>
            </div>
            <div class="col-6">

            </div>
            <div class="col-2">
                <button 
                  v-if="btns[1]!==undefined"
                  class="btn-inbar" 
                  :class="{ 'main': btns[1].isMain }"
                  v-on:click="$emit(btns[1].action)"
                >
                    <span class="text">{{btns[1].name}}</span> 
                </button>
            </div>
            <div class="col-2">
                <button 
                  v-if="btns[2]!==undefined"
                  class="btn-inbar" 
                  :class="{ 'main': btns[2].isMain }"
                  v-on:click="$emit(btns[2].action)"
                >
                    <span class="text">{{btns[2].name}}</span> 
                </button>
            </div>
        </div>        
      </div>

      <div 
       v-if="type === 'two-btn'" 
       class="d-flex align-items-center"
      >
        <div class="col-11 mx-auto d-flex">
            <div class="col-2">
                <button 
                  v-if="btns[0]!==undefined"
                  class="btn-inbar" 
                  :class="{ 'main': btns[0].isMain }"
                  v-on:click="$emit(btns[0].action)"
                >
                    <span class="text">{{btns[0].name}}</span> 
                </button>
            </div>
            <div class="col-8">

            </div>
            <div class="col-2">
                <button 
                  v-if="btns[1]!==undefined"
                  class="btn-inbar" 
                  :class="{ 'main': btns[1].isMain }"
                  v-on:click="$emit(btns[1].action)"
                >
                    <span class="text">{{btns[1].name}}</span> 
                </button>
            </div>
        </div>        
      </div>  
      <div 
       v-if="type === 'one-btn-center'" 
       class="d-flex align-items-center"
      >
        <div class="col-12 mx-auto d-flex">

            <div class="col-12">
                <button 
                  v-if="btns[0]!==undefined"
                  class="btn-inbar" 
                  :class="{ 'main': btns[0].isMain }"
                  v-on:click="$emit(btns[0].action)"
                >
                    <span class="text">{{btns[0].name}}</span> 
                </button>
            </div>
            
        </div>        
      </div>   

    </div>
</template>
<script>
export default {
    props: {
        type: String, // three-btn, two-btn, one-btn-center,
        btns: Array,
    }
}
</script>

<style scope>
.bottom-bar {
  bottom: 0;
  background-color:#ffffff;
  height: 160px;
  width: 100vw;
}
.bottom-bar .btn-inbar{
  border-radius: 60px;
  border-width: 0px;
  border-style: solid;
  background-color: #DBE5FD;
  width: 270px;
  height: 110px;
}
.bottom-bar .btn-inbar .text {
  color: #024FA1;
  font-weight: bold;
  font-size: 45px;
}
.bottom-bar .btn-inbar.main{
  background-color: #A3CBFB;
}
.w-95percent {
  width: 95%;
}
</style>