<template>
  <div>
    <div class="upper-min-height">
      <Header :title="pageStatus.title" />
      <div class="row my-4 py-4">
        <div class="col-10 main-panel mx-auto later-pay-css">
          <div class="my-4 py-4">
            <div class="text-center my-4">
              <div>
                  您的訂單編號 <span class="text-red">{{order_number}}。</span> 
              </div> 
              <div>
                  請自行收藏保存紀錄
              </div>
              <div class="pb-4">
                  總計<span class="text-red">{{ticket_count}}</span>張船票，領取時請確認數量
              </div>
            </div>
          </div>
          <div 
           class="my-4 py-4 d-flex align-items-end justify-content-center fs-default"
           style="height:200px"
          >
              畫面於<span class="text-red mx-1">15</span>秒後回至首頁
          </div>

        </div>
      </div>
    </div>

    <BottomBar
      :type="'one-btn-center'"
      :btns="bottomBtns"
      @back-homepage="$router.push('/')"
    />
  </div>
</template>
<script>
import Header from "@/components/layout-components/tvm/tvmHeader.vue";
import BottomBar from "@/components/layout-components/tvm/tvmBottomBar.vue";

export default {
  props: {
    order_number: String,
    ticket_count: Number,
  },
  components: {
    Header,
    BottomBar
  },
  data() {
    return {
      pageStatus: {
        title: "",
        adult_NumList: [],
        children_NumList: [],
      },
      selecting: {
        adultTickets: 0,
        childrenTickets: 0,
      },
      bottomBtns: [
        {
          name: "返回首頁",
          action: "back-homepage",
          isMain: true,
        },

      ],
    };
  },
  methods: {
    init() {
      let thisVue = this ;
      setTimeout( function(){ thisVue.$router.push('/')}, 15000 );
    },

  },
  mounted() {
    this.init();
  },
};
</script>
<style scope>

.later-pay-css {
  color: #024fa1;
  font-size: 60px;
  line-height: 100px;
  font-weight: bold;
}
</style>
