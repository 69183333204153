<template>
    <div 
     class="modal fade" 
     :id="target_id"
     data-bs-keyboard="false" tabindex="-1" 
     aria-labelledby="staticBackdropLabel" 
     data-bs-backdrop="static"
     aria-hidden="true">
        <div class="modal-dialog term modal-dialog-scrollable">
            <div class="modal-content text-blue">
                <div class="modal-header">
                    <button 
                     v-if="step===0||step===5"
                     type="button"
                     class="btn-close" 
                     data-bs-dismiss="modal" 
                     aria-label="Close"
                     v-on:click="resetOCRModal()"
                    ></button>
                </div>
                <div class="title" v-if="step===0">
                    請擺放證件
                </div>
                <div class="title" v-if="step===1">
                    掃描中
                </div>
                <div class="title" v-if="step===2">
                    取得資料
                </div>
                <div class="title" v-if="step===3||step===4">
                    處理資料中
                </div>
                <div 
                 class="title" 
                 v-if="step===5&&ocrData.ocrRecognizeData.name===''&&ocrData.ocrRecognizeData.idNumber===''&&ocrData.ocrRecognizeData.birthday===''">
                    失敗
                </div>
                <div class="title" v-else-if="step===5">
                    完成
                </div>

                <div class="h-570px d-flex align-items-center justify-content-center">
                    <div class="fs-32px fw-bold d-flex p-4" v-if="step===0">
                        <div class="mx-4 d-flex align-items-center">
                            <img src="@/assets/images/tvm-assets/1920_ocr_demo.png" alt="">
                        </div>
                        <div class="text-start mx-4 d-flex align-items-center" style="line-height: 2em;">
                            <div>
                                <div class="d-flex">
                                    請將
                                    <div class="text-red underline-border">證件正面</div>
                                    面向於玻璃面板並
                                </div>
                                <div class="d-flex">
                                    向
                                    <div class="text-red underline-border">左上靠齊</div>
                                    ，再按下
                                    <div class="text-red underline-border">「掃描」</div>
                                </div>
                                <div class="d-flex">
                                    可進行拍攝及辨識證件資訊。
                                </div>
                            </div>
                        </div>
                    </div>
                    <div 
                     class="spinner-border text-primary"
                     style="height:200px; width:200px;"
                     v-if="step===1||step===2||step===3||step===4" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div 
                     class="fs-45px fw-bold"
                     v-if="step===5&&ocrData.ocrRecognizeData.name===''&&ocrData.ocrRecognizeData.idNumber===''&&ocrData.ocrRecognizeData.birthday===''">
                        <div style="padding: 50px 0px;">
                            <img src="~@/assets/images/tvm-assets/1920_icon_error.svg">
                        </div>
                        取得資料失敗，請確認是否已正確放上證件
                    </div>
                    <div 
                     class="fs-45px fw-bold"
                     v-else-if="step===5">
                        <div style="padding: 50px 0px;">
                            <img src="~@/assets/images/tvm-assets/1920_icon_sucess.svg" style="height:200px; width:200px;">
                        </div>
                        取得資料成功
                    </div>
                </div>
                
                
                
                <div class="modal-footer justify-content-center py-4">
                    <button 
                     type="button" 
                     class="btn btn-modal-close fw-bold"
                     v-on:click="doScan()"
                     v-if="step===0"
                    >掃描</button>
                    <button 
                     type="button" 
                     class="btn btn-modal-close fw-bold"
                     data-bs-dismiss="modal" 
                     v-on:click="resetOCRModal()"
                     v-if="step===5"
                    >返回</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ocrWebSocket from '@/utils/ocr-websocket.js' ;
export default {
    props: {
        target_id: String,
    },
    data() {
        return {
            step: 0,
            ocrIsSetup: false,
            ocrData: {
                ocrDeviceName: "",
                ocrTempFileName: "",
                ocrRecognizeData: {
                    name: "",
                    birthday: "",
                    idNumber: "",
                    phone: "",
                },
                isScaning: false,
            },
            tempData: ""
        }
    },
    watch: {
        'step': async function() {
            console.log('step change, now status:');
            console.log('step', this.step);
            console.log('ocrIsSetup',this.ocrIsSetup)
            console.log('ocrData',this.ocrData);
            console.log('tempData', this.tempData);
            if ( this.step === 2 ) {
                await this.getRecoginzeResult() ;
            }
            else if ( this.step === 3 ) {
                this.postProcessData(this.tempData) ;
            }
            else if ( this.step === 4 ) {
                await this.removeFile() ;
            }
            else if ( this.step === 5 ) {
                this.showResult() ;
            }
        }
    },
    methods: {
        async setScanDevice() {
            console.log('init, setScanDevice');
            let deviceNameSocket = await ocrWebSocket.getDeviceList('127.0.0.1') ;
            let vm = this ;
            deviceNameSocket.onmessage = function(e) {
                console.log('socketData(DeviceName):', e.data);
                vm.ocrData.ocrDeviceName = e.data.split(' ')[1] ;
                vm.ocrIsSetup = true ;
            }
        },
        async doScan() {
            console.log('step1, doScan');
            this.ocrData.isScaning = true ;
            this.step = 1 ;
            let deviceScanResult = await ocrWebSocket.sendScanParam('127.0.0.1', this.ocrData.ocrDeviceName ) ;
            let catchResult = false ;
            let vm = this ;
            deviceScanResult.onmessage = function(e) {
                console.log(e.data);
                if ( e.data.split('.')[1] === 'jpg' && !catchResult ) {
                    catchResult = true ;
                    vm.ocrData.ocrTempFileName = e.data.split(',')[1].split('.')[0] ;
                    console.log( "掃描儲存完成, filename:", vm.ocrData.ocrTempFileName );
                    vm.ocrData.isScaning = false;
                    vm.step = 2 ;
                }
            }
            deviceScanResult.onclose = function(e) {
                console.log(e.data);
                vm.step = 2 ;
            }
        },
        async getRecoginzeResult() {
            console.log('step2, getRecoginzeResult');
            this.step = 2 ;
            let deviceRecognizeResult = await ocrWebSocket.getRecognizeData( '127.0.0.1', this.ocrData.ocrTempFileName );
            let vm = this ;
            deviceRecognizeResult.onmessage = function(e) {
                console.log(e.data);
                vm.tempData = e.data ;
                vm.step = 3 ;
            }
            deviceRecognizeResult.onclose = function(e) {
                console.log(e.data);
                vm.step = 3 ;
            }
            
        },
        async removeFile() {
            console.log('step4, removeFile'); // remove ocr generate img&json ;
            let recognizeResultRemoveSocketJPG = await ocrWebSocket.removeFile( '127.0.0.1', this.ocrData.ocrTempFileName + '.jpg' );
            let vm = this ;
            recognizeResultRemoveSocketJPG.onclose = async function(e) {
                console.log(e,"close");
                vm.step = 5 ;
            }
        },
        postProcessData(jsonData) {
            console.log('step3, postProcessData', jsonData);
            let data = jsonData !== "data:" ? JSON.parse(jsonData.substring(jsonData.indexOf(':') + 1)) : "" ; // remove 'data:' and parse to obj ;
            console.log("JSON parse result");
            console.log(data);
            console.log("end result");
            if ( data.Name !== undefined ) {
                this.ocrData.ocrRecognizeData.name = data.Name ;
                console.log(this.ocrData.ocrRecognizeData.name);
            }
            if ( data.ID !== undefined ) {
                this.ocrData.ocrRecognizeData.idNumber = data.ID ;
                console.log(this.ocrData.ocrRecognizeData.idNumber);
            }
            if ( data.Birthday !== undefined ) {
                this.ocrData.ocrRecognizeData.birthday = this.convertROCDateToADDate(data.Birthday) ;
                console.log(this.ocrData.ocrRecognizeData.birthday);
            }
            if ( data.Name === undefined && data.ID === undefined && data.Birthday === undefined ) {
                console.log('未取得資料');
                this.ocrData.ocrRecognizeData = {
                    name: "",
                    idNumber: "",
                    birthday: "",
                    phone: "",
                };
            }
            console.log('emit data:', this.ocrData.ocrRecognizeData);
            this.$emit('emit-recognize', this.ocrData.ocrRecognizeData);
            this.step = 4 ;

        },
        convertROCDateToADDate( date ) {
            let rocYear = date.split('國')[1].split('年')[0] ;
            let rocMonth = date.split('年')[1].split('月')[0] ;
            if ( rocMonth.length < 2 && parseInt(rocMonth) < 10 ) {
                rocMonth = "0" + rocMonth ;
            }
            let rocDate = date.split('月')[1].split('日')[0] ;
            let adYear = parseInt(rocYear) + 1911 ;
            return adYear + '-' + rocMonth + '-' + rocDate ;
        },
        showResult() {

        },
        resetOCRModal() {
            this.step = 0 ;
            let deviceNameTemp = this.ocrData.ocrDeviceName ;
            this.ocrData = {
                ocrDeviceName: deviceNameTemp,
                ocrTempFileName: "",
                ocrRecognizeData: {
                    name: "",
                    birthday: "",
                    idNumber: "",
                    phone: "",
                },
                isScaning: false,
            } ;
            this.tempData = "" ;
        }
    },
    async mounted() {
        await this.setScanDevice() ;
    }
}
</script>
