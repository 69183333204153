<template>
  <div>
    <div class="upper-min-height" v-if="!isError">
      <Header :title="''" />
      <div class="row my-4 py-4">
        <div class="col-10 main-panel mx-auto later-pay-css">
          <div class="my-4 py-4">
            <div class="text-center my-4">
              <div>
                  船票正在列印中
              </div> 
              <div class="pb-4">
                  請稍後
              </div>
              <div class="py-4">
                  <img src="~@/assets/images/tvm-assets/1920_icon_waiting_circle.svg">
              </div>
            </div>
          </div>
          <div 
           class="my-4 py-4 d-flex align-items-end justify-content-center fs-default"
           style="height:200px"
          >
            本次交易有<span class="text-red mx-4">{{ passengerData.length * 2 }}</span>張船票，領取時確認數量
          </div>

        </div>
      </div>
    </div>
    <div class="upper-min-height" v-if="isError">
      <Header :title="''" />
      <div class="row my-4 py-4">
        <div class="col-10 main-panel mx-auto later-pay-css">
          <div class="my-4 py-4">
            <div class="text-center my-4">
              <div class="py-4">
                  <img src="~@/assets/images/tvm-assets/1920_icon_error.svg">
              </div>
              <div class="mx-auto" style="width:1200px;">
                  {{ errorMessage }}
              </div> 
            </div>
          </div>
          

        </div>
      </div>
    </div>
    <BottomBar v-if="isError"
        :type="'one-btn-center'"
        :btns="bottomBtns"
        @re-pick="$emit('re-pick')"
    />

  </div>
</template>
<script>
import Header from "@/components/layout-components/tvm/tvmHeader.vue";
import BottomBar from "@/components/layout-components/tvm/tvmBottomBar.vue";
import printJS from 'print-js';
import { apiPostGetTicket } from "@/apis/download.js";
import { apiPostGetTicketPDF } from '@/apis/download.js';

export default {
  props: {
    orderData: Object,
    passengerData: Array,
  },
  components: {
    Header,
    BottomBar,
  },
  data() {
    return {
      isError: false,
      errorMessage: "",
      bottomBtns: [
        {
          name: "重新取票",
          action: "re-pick",
          isMain: true,
        },
      ],
    };
  },
  methods: {
    async init() {
      await this.sendDownloadTicket() ;
      let pdfurl = await this.getPDFURL();
      printJS({
        printable: pdfurl,
        type: 'pdf'
      });
      let thisVue = this ;
      setTimeout( function(){ thisVue.$emit( 'next-step' ) }, 10000 );
      
    },
    async sendDownloadTicket() {
      
      let passengers = [];
      for ( let i = 0 ; i < this.passengerData.length ; i++) {
          let passenger = {
            fare_id: this.getFareID(this.passengerData[i].fare),
            name: this.passengerData[i].name,
            id_number: this.passengerData[i].idNumber,
            phone: this.passengerData[i].phone,
            is_passport: this.passengerData[i].isPassport,
          }
          passengers.push(passenger);
      }
      
      let data = { 
        order_number: this.orderData.order_number,
        info: this.orderData.info,
        passengers: passengers,
      };
    //   console.log("download request data",data);
      let result = await apiPostGetTicket(data);
      if ( result.status === 200 ) {
          console.log("sucess",result);
      }
      else {
          console.log("fail",result);
          if ( result.status === 400 ) {
            // 重複身分證 ;
            this.isError = true;
            this.errorMessage = result.message;
          }
          
      }
      return result;

    },
    async getPDFURL() {
        let passengers = [];
        for ( let i = 0 ; i < this.passengerData.length ; i++) {
            let passenger = {
                id_number: this.passengerData[i].idNumber,
                is_passport: this.passengerData[i].isPassport,
            }
            passengers.push(passenger);
        }
        let printPDFData = {
            order_number: this.orderData.order_number,
            info: this.orderData.info,
            passengers: passengers,
        };
        let result = await apiPostGetTicketPDF(printPDFData);
        if ( result.status === 200 ) {
            return result.data.url;
        }
    },
    getFareID( type ) {
        for ( let i = 0 ; i < this.orderData.data.fares.length ; i++ ) {
            if ( this.orderData.data.fares[i].type === type )
                return this.orderData.data.fares[i].id;
        }
        return 0 ;
    }

  },
  mounted() {
    this.init();
  },
};
</script>
<style scope>

.later-pay-css {
  color: #024fa1;
  font-size: 60px;
  line-height: 100px;
  font-weight: bold;
}
</style>
