<template>
  <div>
    <Header :title="'請稍候'" />

    <div class="row my-4 py-4">
        <div class="col-10 main-panel mx-auto">
            <div class="py-4" >
              <div class="py-4"></div>
              <div class="spinner-border text-primary" role="status" style="height:200px; width:200px;">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="py-4"></div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/layout-components/tvm/tvmHeader.vue";


export default {
  components: {
    Header,
  },
};
</script>
<style scope>

</style>
