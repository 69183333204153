<template>
  <div class="container-fluid bg-instep">
    <div v-if="loading">
      <LoadingPage/>
    </div>
    <div v-else-if="!loading">
      <div v-if="status==='order-info'">
        <OrderInfo 
          :order_number="$route.params.order_number"
          :data="pageData"
          @pay-later="fetchPayLater"
          @pay-now="fetchPayNow"
        />
      </div>
      <div v-else-if="status==='pay-later'">
        <LaterPay
          :order_number="payLaterData.order_number"
          :ticket_count="payLaterData.ticket_count"
        />
      </div>
      <div v-else-if="status==='pay-now'">
        <NowPay 
          :order_number="$route.params.order_number"
          :info="$route.params.info"
          @get-prime-and-cardowner-info="fetchPrimeAndOwnerInfo"
        />
      </div>
      <div v-else-if="status==='paying'">
        <PayingRedirect
          :redirect_url="redirect_url"
          @pay-redirect="fetchEndRedirect"
        />
      </div>

    </div>

  </div>
</template>
<script>
import LoadingPage from '@/components/pages/loadingPage.vue';
import OrderInfo from '@/components/pages/tvmOrderInfo/orderInfo/orderInfo.vue';
import LaterPay from '@/components/pages/tvmOrderInfo/payLater/payLater.vue';
import NowPay from '@/components/pages/tvmOrderInfo/pay/creditCardPay.vue';
import PayingRedirect from '@/components/pages/tvmOrderInfo/pay-loading/payLoadingRedirect.vue'

import { apiGetSearchOrder } from '@/apis/download.js';
import { apiPostPay } from '@/apis/pay.js';


export default {
  components: {
    LoadingPage,
    OrderInfo,
    LaterPay,
    NowPay,
    PayingRedirect
  },
  data() {
      return {
        loading: true,
        status: 'order-info',
        pageData: {},
        
        payLaterData: {},
        payNowData: {},

        redirect_url: "",
      }
  },
  methods: {
    async init() {
      await this.sendSearchRequest();
      this.detectPayNow();
    },
    async sendSearchRequest() {
      this.loading = true;
      let data = {
        order_number: this.$route.params.order_number,
        info: this.$route.params.info,
      };
      let results = await apiGetSearchOrder(data);
      if ( results.status === 200 ) {
        console.log("get order data");
        this.pageData = results.data;
      } 
      else {
        console.log("fail");
      }
      this.loading = false;
    },
    async sendPayRequest( prime, ownerData ) {
      this.loading = true;
      
      let data = {
        order_number: this.$route.params.order_number,
        info: this.$route.params.info,
        prime: prime,
        name: ownerData.name,
        phone: ownerData.phone,
        email: ownerData.email,
        type: 1,
      };
      // console.log(data);
      let results = await apiPostPay(data);
      if ( results.status === 200 ) {
        // console.log(results);
        this.redirect_url = results.data.redirect_url;
        this.status = 'paying';
      }
      else {
        console.log(results);
      }

      this.loading = false;
    },
    fetchPayLater( result ) {
      this.payLaterData = result;
      this.status = 'pay-later';
    },
    fetchPayNow( result ) {
      this.payNowData = result;
      this.status = 'pay-now';
    },
    fetchPrimeAndOwnerInfo( result ) {
      this.sendPayRequest( result.prime, result.cardOwner );
    },
    fetchEndRedirect( ) {
      // 得到tappay填完驗證碼的emit;
      // 應該要跳到ticket list(此頁為取票頁面-輸入完訂單資訊後) 該頁面檢查是否付款完成;
      this.$router.push( { 
        path:"/taking?order_number=" 
        + this.$route.params.order_number + "&info=" 
        + this.$route.params.id_4
       } );
    },
    detectPayNow() {
      if ( this.$route.query.paynow ) {
        this.status = 'pay-now';
      }
    }
  },
  mounted() {
    this.init();
  }
}
</script>
<style scope>

</style>
