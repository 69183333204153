<template>
  <div class="container-fluid bg-instep">
    <div v-if="step === 99">
      <LoadingPage />
    </div>
    <div v-if="step === 1">
      <SearchOrder @back-step="step--" @next-step="fetchOrderData" />
    </div>
    <div v-if="step === 2">
      <TicketList 
        :data="pageData.orderData.data"
        :order_number="pageData.orderData.order_number"
        :info="pageData.orderData.info"
        @back-step="step--"
        @next-step="fetchTakeFaresData"
      />
    </div>
    <div v-if="step === 3">
      <FillPassengerInfo 
        :adultTicketsNum="pageData.faresData.adult"
        :childrenTicketsNum="pageData.faresData.children"
        @back-step="step--"
        @next-step="fetchPassengerData"
      />
    </div>
    <div v-if="step === 4">
      <TakingDetail 
       :data="pageData.orderData.data"
       :order_number="pageData.orderData.order_number"
       :info="pageData.orderData.info"
       :tickets_info="pageData.ticketsDetail"
       @back-step="step--"
       @print="fetchPrint"
      />
    </div>
    <div v-if="step === 5">
       <PrintPage
        :orderData="pageData.orderData"
        :passengerData="pageData.passengerData"
        @next-step="step++"
        @re-pick="step=2"
       />
    </div> 
    <div v-if="step === 6">
       <EndPrintPage />
    </div>
  </div>
</template>
<script>
import LoadingPage from '@/components/pages/loadingPage.vue';
import SearchOrder from '@/components/pages/tvmTaking/searchOrder/searchOrder.vue';
import TicketList from '@/components/pages/tvmTaking/ticketList/ticketList.vue';
import FillPassengerInfo from '@/components/pages/tvmTaking/fillPassengerInfo/fillPassengerInfo.vue';
import TakingDetail from '@/components/pages/tvmTaking/takingDetail/takingDetail.vue';
import PrintPage from '@/components/pages/tvmTaking/printPage/printingPage.vue';
import EndPrintPage from '@/components/pages/tvmTaking/endPrintWaitRedirect/endPrintWaitRedirect.vue';

export default {
  components: {
    LoadingPage,
    SearchOrder,
    TicketList,
    FillPassengerInfo,
    TakingDetail,
    PrintPage,
    EndPrintPage,
  },
  watch: {
    step: function() {
        if ( this.step === 0 )  {
            this.$router.push('/');
        }
    }
  },
  data() {
    return {
        step: 1,
        pageData: {
            orderData: {},
            // orderData: {
            //     info: "6789",
            //     order_number: "I2107280703",
            //     data: {
            //         fares: [
            //             {
            //                 count:2,
            //                 id: 72,
            //                 is_round_trip:1,
            //                 name: "網路全票",
            //                 price: 410,
            //                 remain:2,
            //                 type: "adult"
            //             },
            //             {
            //                 count:1,
            //                 id: 72,
            //                 is_round_trip:1,
            //                 name: "網路半票",
            //                 price: 410,
            //                 remain:1,
            //                 type: "children"
            //             }
            //         ],
            //         paid: true,
            //         schedule: {
            //             DL: {
            //                 date: "2021-08-02",
            //                 time: "13:20",
            //             },
            //             LD: {
            //                 date: "2021-08-03",
            //                 time: "16:00"
            //             }
            //         }
            //     }
            // },
            faresData: {},
            passengerData: {},
            ticketsDetail: {},
            
        },
    }
  },
  methods: {
    fetchOrderData( result ) {
        this.pageData.orderData = result;
        this.step++;
    },
    fetchTakeFaresData( result ) {
        this.pageData.faresData = result;
        this.step++;
    },
    fetchPassengerData( result ) {
        this.pageData.passengerData = result;
        this.pageData.ticketsDetail = this.getTicketsDetails();
        this.step++;
    },
    async fetchPrint() {
        this.step++;
    },
    getTicketsDetails() {
        let takeTickets = [];
        for ( let i = 0 ; i < this.pageData.passengerData.length ; i++ ) {
            let ticketInfo = {
                name: this.pageData.passengerData[i].name,
                idNumber: this.pageData.passengerData[i].idNumber,
                phone: this.pageData.passengerData[i].phone,
                birthday: this.pageData.passengerData[i].birthday,
                type: this.pageData.passengerData[i].fare, // adult or children,
                price: this.getFarePrice(this.pageData.passengerData[i].fare),
            };
            takeTickets.push(ticketInfo);
        }
        return takeTickets;
    },
    getFarePrice( fare_type ) {
        // 從本訂單得到該票種類型價格(全票/半票票價) ;
        for ( let i = 0 ; i < this.pageData.orderData.data.fares.length ; i++ ) {
            if ( this.pageData.orderData.data.fares[i].type === fare_type )
                return this.pageData.orderData.data.fares[i].price;
          
        }
        return 0 ;
    },
    init() {
        

    },


  },
  mounted() {
      this.init();
  }
}
</script>
<style scope></style>
