<template>
  <div>
    <div v-if="results.passengerInfo.length < totalTicketNum">
      <div class="upper-min-height">
        <Header :title="pageStatus.title" />
        <div class="row my-4 py-4">
          <div class="col-10 main-panel mx-auto fs-default">
            <div
              class="text-start text-blue fw-bold d-flex justify-content-between"
            >
              <div>
                請輸入每位乘客資訊，輸入完請確認是否無誤，並按「下一步」
              </div>
              <div>
                已填寫
                <span class="text-red">{{ results.passengerInfo.length }}</span
                >/{{ totalTicketNum }}
              </div>
            </div>
            <div class="col-12 d-flex justify-content-between my-4">
              <div class="tvm-panel">
                <div
                  class="header d-flex align-items-center justify-content-start"
                >
                  <div class="text fw-bold col-11 mx-auto text-start d-flex justify-content-between">
                    <div class="text-title d-flex justify-content-between">
                      乘客資訊{{ results.passengerInfo.length + 1 }}
                    </div>
                    <div class="mx-4">
                      <span class="text-red">*</span>為必填
                    </div>
                  </div>
                </div>
                <div class="body py-4">
                  <div
                    class="col-11 mx-auto text-start my-4 d-flex pb-4 justify-content-between"
                  >
                    <div
                      class="d-flex align-items-center col-6 justify-content-between"
                    >
                      <div class="text-title d-flex justify-content-start">
                        取票人姓名
                      </div>
                      <div class="mx-4">
                        <input
                          type="text"
                          class="input-text px-3 pt-2"
                          :class="{ 'notice' : pageStatus.notices[0] !== undefined && pageStatus.notices[0].desc !== 'success' }"
                          :placeholder="
                            ( pageStatus.notices[0] !== undefined && pageStatus.notices[0].desc !== 'success' ) ? 
                            ( pageStatus.notices[0].desc === 'null' ? '取票人姓名為必填':'' ) : '請輸入取票人姓名'
                          "
                          v-model="inputs.name"
                        />
                      </div>
                    </div>
                    <div
                      class="d-flex align-items-center col-6 justify-content-end"
                    >
                      <div class="text-title text-end mx-4">
                        <div>
                          出生日期
                        </div>
                        <div class="fs-32px">
                          (1901-01-01)
                        </div>
                      </div>
                      <div class="mx-4">
                        <input
                          type="text"
                          class="input-text px-3 pt-2"
                          :class="{ 'notice' : pageStatus.notices[2] !== undefined && pageStatus.notices[2].desc !== 'success'  && inputs.birthday === '' }"
                          :placeholder="
                            ( pageStatus.notices[2] !== undefined && pageStatus.notices[2].desc !== 'success' ) ? 
                            ( ( pageStatus.notices[2].desc === 'null' ) ? '出生日期為必填':'出生日期不符合規則' ) : '請輸入出生日期'
                          "
                          v-model="inputs.birthday"
                          v-on:keyup="dateKeyUp()"
                          v-on:keyup.delete="dateKeyUpDelete()"
                          maxlength="10"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-11 mx-auto text-start my-4 d-flex">
                    <div
                      class="d-flex align-items-center col-6 justify-content-between"
                    >
                      <div class="text-title">
                        <div>
                          身分證字號<span class="text-red">*</span>
                        </div>
                        <div class="fs-32px">
                          (護照號碼請勾選)
                        </div>
                      </div>
                      <div class="mx-4">
                        <input
                          type="text"
                          class="input-text px-3 pt-2"
                          :class="{ 'notice' : pageStatus.notices[1] !== undefined && pageStatus.notices[1].desc !== 'success' }"
                          :placeholder="
                            ( pageStatus.notices[1] !== undefined && pageStatus.notices[1].desc !== 'success' ) ? 
                            ( pageStatus.notices[1].desc === 'null' ? 
                              (inputs.isPassport ? '護照號碼' : '身分證字號') + '為必填' : (inputs.isPassport ? '護照號碼' : '身分證字號') + '不符合規則' ) 
                              : ( '請輸入' + (inputs.isPassport ? '護照號碼' : '身分證字號')
                            )
                          "
                          v-model="inputs.idNumber"
                          v-on:keyup="inputs.idNumber=inputs.idNumber.replace(/[\W]/g,'')"
                        />
                      </div>
                    </div>
                    <div
                      class="d-flex align-items-center col-6 justify-content-end"
                    >
                      <div class="text-title d-flex justify-content-end">
                        行動電話<span class="text-red">*</span>
                      </div>
                      <div class="mx-4">
                        <input
                          type="text"
                          class="input-text px-3 pt-2"
                          :class="{ 'notice' : pageStatus.notices[3] !== undefined && pageStatus.notices[3].desc !== 'success' }"
                          :placeholder="
                            ( pageStatus.notices[3] !== undefined && pageStatus.notices[3].desc !== 'success' ) ? 
                            ( pageStatus.notices[3].desc === 'null' ? 
                              '行動電話為必填' : '行動電話不符合規則' ) 
                              : '請輸入行動電話'
                          "
                          v-model="inputs.phone"
                          v-on:keyup="inputs.phone=inputs.phone.replace(/\D/g,'')"
                          v-on:onafterpaste="inputs.phone=inputs.phone.replace(/\D/g,'')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-11 mx-auto text-start mt-4 d-flex">
                    <div
                      class="d-flex align-items-center col-6 justify-content-start"
                    >
                      <div
                        class="text-title d-flex justify-content-start"
                      ></div>
                      <div class="mx-4 d-flex align-items-center">
                        <div>
                          <CustomCheckbox 
                           :border="true" 
                           :boolean="inputs.isPassport"
                           @checked="inputs.isPassport = !inputs.isPassport"
                          />
                        </div>
                        <div class="mx-4">
                          護照號碼
                        </div>
                      </div>
                    </div>
                    <div
                      class="d-flex align-items-center col-6 justify-content-end"
                    >
                      <div class="text-title d-flex justify-content-end">
                        選擇票別
                      </div>
                      <div
                        class="mx-4 input-text border-0 d-flex align-items-center justify-content-start"
                      >
                        <div class="d-flex align-items-center">
                          <div>
                            <CustomCheckbox
                              :border="true"
                              :boolean="inputs.isAdult"
                              :disabled="!isFaresRemainAdult"
                              @checked="inputs.isAdult = !inputs.isAdult"
                            />
                          </div>
                          <div class="mx-4">
                            全票
                          </div>
                        </div>
                        <div class="d-flex align-items-center mx-4">
                          <div>
                            <CustomCheckbox
                              :border="true"
                              :boolean="inputs.isChildren"
                              :disabled="!isFaresRemainChildren"
                              @checked="inputs.isChildren = !inputs.isChildren"
                            />
                          </div>
                          <div class="mx-4">
                            半票
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-11 mx-auto text-start d-flex" style="height:60px;">
                    <div
                      class="d-flex align-items-center col-6 justify-content-start"
                    ></div>
                    <div
                      class="d-flex align-items-center col-6 justify-content-center text-red"
                      v-if="pageStatus.notices[4] !== undefined && pageStatus.notices[4].desc !== 'success'
                            && !inputs.isAdult && !inputs.isChildren"
                    >
                      請選擇票種
                    </div>
                  </div>
                  <div class="col-11 mx-auto text-start my-4 d-flex">
                    <div
                      class="d-flex align-items-center col-6 justify-content-between"
                    >
                      <div
                        class="text-title d-flex justify-content-start"
                      ></div>
                      <div class="mx-4 d-flex align-items-center input-checkbox">
                      </div>
                    </div>
                    <div
                      class="d-flex align-items-center col-6 justify-content-end"
                    >
                      <div class="mx-4">
                        <button 
                        v-if="pageStatus.ocrIsSetup"
                        class="btn-functional"
                        data-bs-toggle="modal" data-bs-target="#ocrModal"
                        >掃描證件自動輸入</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OCRModal
      :target_id="'ocrModal'"
      @emit-recognize="setInput"
      />
      <BottomBar
        :type="'three-btn'"
        :btns="bottomBtns"
        @back-homepage="$router.push('/')"
        @back-step="backStep()"
        @next-step="nextStep()"
      />
    </div>

    <div v-else-if="results.passengerInfo.length === totalTicketNum">
      <div class="upper-min-height">
        <Header :title="'確認乘客資訊'" />
        <div class="row my-4 py-4">
          <div class="col-10 main-panel mx-auto fs-default">
            <div
              class="text-start text-blue fw-bold d-flex justify-content-between"
            >
              <div>
                請確實每位乘客資訊是否無誤，確認完請按「確認送出」
              </div>
              <div>
                乘客人數共計
                <span class="text-red">{{ results.passengerInfo.length }}</span>
                名
              </div>
            </div>
            <div class="col-12 d-flex justify-content-between my-4">
              <div class="tvm-panel">
                <div
                  class="header d-flex align-items-center justify-content-start"
                >
                  <div
                    class="text fw-bold col-11 mx-auto text-start d-flex justify-content-between"
                  >
                    <div class="t-350px text-nowrap">乘客姓名</div>
                    <div class="t-400px text-nowrap">身分證/護照</div>
                    <div class="t-350px text-nowrap">出生日期</div>
                    <div class="t-350px text-nowrap">行動電話</div>
                    <div class="t-150px text-nowrap">票別</div>
                  </div>
                </div>
                <div class="body py-4 fs-45px">
                  <div
                    class="fw-bold col-11 mx-auto text-start d-flex align-items-center justify-content-between py-4"
                    v-for="(item, index) in results.passengerInfo"
                    :key="index"
                    :class="{
                      't-row-bottom-border':
                        index != results.passengerInfo.length - 1,
                    }"
                  >
                    <div class="t-350px">{{item.name}}</div>
                    <div class="t-400px">{{item.idNumber}}</div>
                    <div class="t-350px">{{item.birthday}}</div>
                    <div class="t-350px">{{item.phone}}</div>
                    <div class="t-150px">{{item.fare==='adult'?'全票':'半票'}}</div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
      <BottomBar
        :type="'three-btn'"
        :btns="[bottomBtns[0], bottomBtns[1], bottomBtns[3]]"
        @back-homepage="$router.push('/')"
        @back-step="backStep()"
        @next-step="nextStep()"
      />
    </div>

  </div>
</template>
<script>
import Header from "@/components/layout-components/tvm/tvmHeader.vue";
import BottomBar from "@/components/layout-components/tvm/tvmBottomBar.vue";
import CustomCheckbox from "@/components/basic-components/custom-checkbox.vue";
import ocrWebSocket from '@/utils/ocr-websocket.js' ;
import OCRModal from "@/components/basic-components/ocr-modal/ocr-modal.vue";

export default {
  props: {
    adultTicketsNum: Number,
    childrenTicketsNum: Number,
  },
  computed: {
    totalTicketNum() {
      return this.adultTicketsNum + this.childrenTicketsNum;
    },
    isFaresRemainAdult() {
      return this.results.adult < this.adultTicketsNum;
    },
    isFaresRemainChildren() {
      return this.results.children < this.childrenTicketsNum;
    },
  },
  watch: {
    "inputs.isAdult": function() {
      if (this.inputs.isChildren && this.inputs.isAdult) {
        this.inputs.isChildren = false;
      }
    },
    "inputs.isChildren": function() {
      if (this.inputs.isAdult && this.inputs.isChildren) {
        this.inputs.isAdult = false;
      }
      if (
        this.inputs.isChildren &&
        this.inputs.birthday !== "" &&
        this.inputs.birthday < this.getHalfTicketLimit()
      ) {
        this.inputs.birthday = "";
      }
    },
  },
  components: {
    Header,
    BottomBar,
    CustomCheckbox,
    OCRModal,
  },
  data() {
    return {
      pageStatus: {
        title: "輸入乘客資訊",
        isShowingDateSelect: false,
        notices: [],
        ocrIsSetup: false,
      },
      ocrData: {
        ocrDeviceName: "",
      },
      inputs: {
        name: "",
        birthday: "",
        idNumber: "",
        phone: "",
        isAdult: false,
        isChildren: false,
        isPassport: false,
      },
      results: {
        passengerInfo: [],
        adult: 0,
        children: 0,
      },
      bottomBtns: [
        {
          name: "取消取票",
          action: "back-homepage",
          isMain: false,
        },
        {
          name: "返回",
          action: "back-step",
          isMain: false,
        },
        {
          name: "下一步",
          action: "next-step",
          isMain: true,
        },
        {
          name: "確認送出",
          action: "next-step",
          isMain: true,
        },
      ],
    };
  },
  methods: {
    dateKeyUp() {
      
      if (/[^\d/-]/g.test(this.inputs.birthday)) 
        this.inputs.birthday = this.inputs.birthday.replace(/[^\d/]/g,'')
        
      if ( this.inputs.birthday.length === 4 ) {
        this.inputs.birthday += '-';
      }
      else if ( this.inputs.birthday.length === 7 ) {
        this.inputs.birthday += '-';
      }
    },
    dateKeyUpDelete() {
      if ( this.inputs.birthday.length === 5 ) {
        this.inputs.birthday = this.inputs.birthday.substring(0,3);
      }
      else if ( this.inputs.birthday.length === 8 ) {
        this.inputs.birthday = this.inputs.birthday.substring(0,6);
      }
    },
    nextStep() {
      if (
        this.validateForm() &&
        this.results.passengerInfo.length < this.totalTicketNum
      ) {
        let passenger = {
          name: this.inputs.name,
          birthday: this.inputs.birthday,
          idNumber: this.inputs.idNumber,
          phone: this.inputs.phone,
          fare: this.inputs.isAdult ? "adult" : "children",
          isPassport: this.inputs.isPassport,
        };
        this.results.passengerInfo.push(passenger);
        if (this.inputs.isAdult) this.results.adult++;
        else {
          this.results.children++;
        }
        this.clearInput();
      } 
      else if (this.results.passengerInfo.length === this.totalTicketNum) {
        this.$emit("next-step", this.results.passengerInfo);
      }
    },
    backStep() {
      if (this.results.passengerInfo.length > 0) {
        let popData = this.results.passengerInfo.pop();
        if (popData.fare === "adult") {
          this.results.adult--;
        } else if (popData.fare === "children") {
          this.results.children--;
        }
        this.inputs.name = popData.name;
        this.inputs.birthday = popData.birthday;
        this.inputs.idNumber = popData.idNumber;
        this.inputs.phone = popData.phone;
        this.inputs.isAdult = popData.fare === "adult" ? true : false;
        this.inputs.isChildren = popData.fare === "children" ? true : false;
      } else if (this.results.passengerInfo.length === 0) {
        this.$emit("back-step");
      }
    },
    clearInput() {
      this.inputs = {
        name: "",
        birthday: "",
        idNumber: "",
        phone: "",
        isAdult: false,
        isChildren: false,
        isPassport: false,
      };
      this.pageStatus.notices = [];
    },
    scrollResult(data) {
      this.inputs.birthday = data;
      this.pageStatus.isShowingDateSelect = false;
    },
    getCurrentYear() {
      return parseInt(new Date(this.inputs.birthday).getFullYear());
    },
    getCurrentMonth() {
      return parseInt(new Date(this.inputs.birthday).getMonth() + 1);
    },
    getCurrentDate() {
      return parseInt(new Date(this.inputs.birthday).getDate());
    },
    validateForm() {
      this.pageStatus.notices = [];
      let hasError = false;

      // if (this.inputs.name === "") {
      //   this.pageStatus.notices.push({
      //     title: "name",
      //     desc: "null",
      //   });
      //   hasError = true;
      // }
      // else {
        this.pageStatus.notices.push({
          title: "name",
          desc: "success",
        });
      // }
      
      if (this.inputs.idNumber === "") {
        this.pageStatus.notices.push({
          title: "id",
          desc: "null",
        });
        hasError = true;
      } 
      else if ( !this.inputs.isPassport && !this.validateIDNumber(this.inputs.idNumber)) {
        this.pageStatus.notices.push({
          title: "id",
          desc: "non-legal",
        });
        hasError = true;
        this.inputs.idNumber = "";
      }
      else if ( this.inputs.isPassport && !this.validatePassport(this.inputs.idNumber)) {
        this.pageStatus.notices.push({
          title: "id",
          desc: "non-legal",
        });
        hasError = true;
        this.inputs.idNumber = "";
      }
      else {
        this.pageStatus.notices.push({
          title: "id",
          desc: "success",
        });
      }

      // if (this.inputs.birthday === "") {
      //   this.pageStatus.notices.push({
      //     title: "birthday",
      //     desc: "null",
      //   });
      //   hasError = true;
      // } 
      if ( this.inputs.birthday !== "" &&
         ( !this.validateDate(this.inputs.birthday) 
         || this.inputs.birthday > this.getToday() 
         || this.inputs.birthday.length < 10 ) ) {
        this.pageStatus.notices.push({
          title: "birthday",
          desc: "non-legal",
        });
        this.inputs.birthday = "";
        hasError = true;
      }
      else if ( this.inputs.birthday !== "" && this.inputs.isChildren && this.inputs.birthday < this.getHalfTicketLimit()) {
        this.pageStatus.notices.push({
          title: "birthday",
          desc: "non-legal",
        });
        this.inputs.birthday = "";
        hasError = true;
      }
      else {
        this.pageStatus.notices.push({
          title: "birthday",
          desc: "success",
        });
      }

      if (this.inputs.phone === "") {
        this.pageStatus.notices.push({
          title: "phone",
          desc: "null",
        });
        hasError = true;
      } else if (!this.validatePhoneNumber(this.inputs.phone)) {
        this.pageStatus.notices.push({
          title: "phone",
          desc: "non-legal",
        });
        hasError = true;
        this.inputs.phone = ""
      }
      else {
        this.pageStatus.notices.push({
          title: "phone",
          desc: "success",
        });
      }

      if ( !this.inputs.isAdult && !this.inputs.isChildren ) {
        // 兩種票種都未選擇 ;
        this.pageStatus.notices.push({
          title: "type",
          desc: "null",
        });
        hasError = true;
      }
      else {
        this.pageStatus.notices.push({
          title: "type",
          desc: "success",
        });
      }

      if (hasError) {
        return false;
      } else return true;
    },
    setInput(recognizeData) {
      this.inputs.name = recognizeData.name ;
      this.inputs.idNumber = recognizeData.idNumber ;
      this.inputs.birthday = recognizeData.birthday ;
      this.inputs.phone = recognizeData.phone ;
    },
    async init() {
      await this.setScanDevice() ;
    },
    async setScanDevice() {
      console.log('setScanDevice');
      let deviceNameSocket = await ocrWebSocket.getDeviceList('127.0.0.1') ;
      let vm = this ;
      deviceNameSocket.onmessage = function(e) {
        console.log('socketData(DeviceName):', e.data);
        vm.ocrData.ocrDeviceName = e.data.split(' ')[1] ;
        vm.pageStatus.ocrIsSetup = true ;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scope></style>
