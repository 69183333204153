import request from '@/utils/request.js';

export const apiGetSearchOrder =
    data => request("get",'/api/v1/ticket/check-get-ticket',data)
            .then(function(res){
                // console.log('apiGetSearchOrder:',res.data);
                return res.data ;
            });

export const apiPostGetTicket =
    data => request("post",'/api/v1/ticket/get-ticket',data)
            .then(function(res){
                // console.log('apiPostGetTicket:',res.data);
                return res.data ;
            });

export const apiPostTakeBackTicket =
    data => request("post",'/api/v1/ticket/get-back',data)
            .then(function(res){
                return res.data ;
            });

export const apiPostGetTicketPDF =
    data => request("post",'/api/v1/ticket/get-pdf',data)
            .then(function(res){
                // console.log('apiPostGetTicket:',res.data);
                return res.data ;
            });