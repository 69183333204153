export default {
    methods: {
        getToday() {
          let date = new Date();
          let yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // 1月為 0 ;
          let dd = date.getDate();
          if (dd < 10) {
            dd = "0" + dd;
          }
          if (mm < 10) {
            mm = "0" + mm;
          }
          
          return yyyy + "-" + mm + "-" + dd;
        },
        getHalfTicketLimit() {
          let date = new Date();
          let yyyy = date.getFullYear()-12;
          let mm = date.getMonth() + 1; // 1月為 0 ;
          let dd = date.getDate();
          if (dd < 10) {
            dd = "0" + dd;
          }
          if (mm < 10) {
            mm = "0" + mm;
          }
          
          return yyyy + "-" + mm + "-" + dd;
        },
        getNowTime: function() {
          // 取得現在 hr:min ;
          let today = new Date() ;
          let hr = today.getHours() ;
          let min = today.getMinutes() ;
          if (hr < 10) {
              hr = '0' + hr ;
          }
          if (min < 10) {
              min = '0' + min ;
          }
          
          return ( hr + ':' + min ) ; 

        },
        getWeekday: function( _time ) {
          // date => weekday ;
          let day = new Date(_time) ;
          var weekday = new Array(7);
          weekday[0] = "(日)";
          weekday[1] = "(一)";
          weekday[2] = "(二)";
          weekday[3] = "(三)";
          weekday[4] = "(四)";
          weekday[5] = "(五)";
          weekday[6] = "(六)";
          return weekday[day.getDay()] ;
        },
        getMMDD: function( _yyyymmdd ) {
            // 將yyyymmdd除去年份 ;
            return _yyyymmdd.substring(5,11) ;
        },
        generateDateString( y, m, d ) {
          if ( m < 10 ) {
            m = "0" + m;
          }
          if ( d < 10 ) {
            d = "0" + d;
          }
          return y + '年' + m + '月' + d + '日' + this.getWeekday(y+'-'+m+'-'+d) ;
        },
        dateStringToYMDObject( str ) {
          let strtemp = str;
          let y = strtemp.split( '年', 2 ); // 2021, 06月30日() ;
          strtemp = y[1];
          y = y[0]; // 2021 ;

          let m = strtemp.split( '月', 2 ); // 06, 30日()
          strtemp = m[1];
          m = m[0];

          let d = strtemp.split( '日', 1 ); 
          d = d[0];
          
          // console.log('y-m-d', y, m, d);
          return {
            y: y,
            m: m,
            d: d,
          }

        },
        dateStringAddDay( dateStr ){
          let ymd_object = this.dateStringToYMDObject(dateStr);
          
          let y = parseInt( ymd_object.y );
          let m = parseInt( ymd_object.m );
          let d = parseInt( ymd_object.d );
          // console.log(y,m,d);
          let isLeapYear = (y % 4 === 0 && y % 100 !== 0) || y % 400 === 0;

          d = d+1;
          if ( isLeapYear && m === 2 && d > 29 ) {
            d = 1;
            m++;
          }
          else if ( !isLeapYear && m === 2 && d > 28 ) {
            d = 1;
            m++;
          }
          else if ( ( m === 1 || m === 3 || m === 5 ||
                      m === 7 || m === 8 || m === 10 ||
                      m === 12 ) 
                    && d > 31 ) {
            d = 1;
            m++;
          } 
          else if ( d > 30 ){
            d = 1;
            m++;
          }

          if ( m > 12 ) {
            m = 1;
            y = y+1;
          }
          
          return this.generateDateString(y,m,d);
          
        },
        dateStringToYMDString( str ) {
          if ( str != "" ) {
            let object = this.dateStringToYMDObject(str);
            return object.y + '-' + object.m + '-' + object.d ;
          }
          else {
            return null;
          }
        },
        moveOneDay(_date, _symbol, _limitDate) {
          // _symbol : 增加/減少一天 ;
          // _limitDate : 不能低於的日期 ; ex: trip1.time 不能低於今天, trip2.time 不能低於blockdate ;
          let date = new Date(_date);
          if (_symbol === "+") date.setDate(date.getDate() + 1);
          else if (_symbol === "-") date.setDate(date.getDate() - 1);
    
          let yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // 1月為 0 ;
          let dd = date.getDate();
          if (dd < 10) {
            dd = "0" + dd;
          }
          if (mm < 10) {
            mm = "0" + mm;
          }
    
          date = yyyy + "-" + mm + "-" + dd;
          
          if (date < _limitDate)
            // 不讓使用者選到低於限制日期 ;
            date = _limitDate; // trip1.time 不能低於今天 ; trip2.time 不能低於 trip1.time ;
    
          return date;
      },
      dateOffset( dateEnd, dateStart ) {
        let dateE = new Date(dateEnd);
        let dateS = new Date(dateStart);
        return (dateE - dateS)/86400000;
      }
    }
    
}