<template>
  <div class="container-fluid bg-instep">
    <div v-if="loading">
      <LoadingPage/>
    </div>
    <div v-else-if="!loading">
      <RedirectResult
        :is_success="this.pageData.paid"
        :order_number="order_number"
        :info="info"
      />

    </div>

  </div>
</template>
<script>
import LoadingPage from '@/components/pages/loadingPage.vue';
import RedirectResult from '@/components/pages/tvmPayRedirect/redirectResult.vue';
import { apiGetSearchOrder } from '@/apis/download.js';

export default {
  components: {
    LoadingPage,
    RedirectResult,
  },
  data() {
      return {
        loading: true,
        order_number: "",
        info: "",
        pageData: {},
      }
  },
  methods: {
    async init() {
      this.loading = true;
      this.getRedirect();
      let paid = await this.sendSearchRequest();
      if ( !paid ) {
        let thisVue = this;
        setTimeout( async function(){
          let paid = await thisVue.sendSearchRequest();
          console.log(paid);
          thisVue.loading = false;
        }, 5000);
      }
      else {
        this.loading = false;
      }
    },
    async sendSearchRequest() {
      
      let data = {
        order_number: this.order_number,
        info: this.info,
      };
      let results = await apiGetSearchOrder(data);
      if ( results.status === 200 ) {
        console.log("get order data");
        this.pageData = results.data;
      } 
      else {
        console.log("fail");
      }
      

      if ( results.status === 200 && results.data.paid ) {
        return true;
      }
      else {
        return false;
      }

    },
    getRedirect() {
      this.order_number = this.$route.params.order_number;
      this.info = this.$route.params.info;
    },

  },
  mounted() {
    this.init();
  }
}
</script>
<style scope>

</style>
