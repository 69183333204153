<template>
  <div id="app" class="h-auto">
    <router-view />
  </div>
</template>
<script>

export default {
  components: {

  },
  methods: {
    init() {
      
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


</style>
