<template>
  <button
    class="col-4 btn-select m-3 btn-w-shipTime"
    :class="{
      selecting: isSelecting,
      disabled: isDisabled,
    }"
    v-on:click="btnClick(time)"
  >
    <span class="text">
      {{ time }}
      <span class="mx-2" v-if="isSpecial">加開</span>
      <span class="mx-2" v-if="isFull">額滿</span>
    </span>
  </button>
</template>
<script>
export default {
  props: {
    time: String,
    isSelecting: Boolean,
    isDeparture: Boolean,
    isDisabled: Boolean,
    isFull: Boolean,
    isSpecial: Boolean,
  },
  computed: {},
  methods: {
    btnClick(time) {
      if (!this.isDisabled) {
        this.$emit("select-time", {
          time: time,
        });
      }
    },
  },
};
</script>
<style scope>

</style>
