<template>
  <div class="container-fluid bg-instep">
    <div v-if="step === 99">
      <LoadingPage/>
    </div>
    <div v-if="step === 1">
      <SelectDate 
       @back-step="step--"
       @next-step="fetchDateSelectResult"
      />
    </div>
    <div v-if="step === 2">
      <SelectTickets 
       @back-step="step--"
       @next-step="fetchTicketSelectResult"
      />
    </div>
    <div v-if="step === 3">
      <SelectShipTime 
       :departure_date="pageData.selectDate.departureDate"
       :return_date="pageData.selectDate.returnDate"
       :ticketCount="pageData.selectTickets.adultTickets+pageData.selectTickets.childrenTickets"
       @back-step="step--"
       @next-step="fetchShipTimeSelectResult"
      />
    </div>
    <div v-if="step === 4">
      <FillBookingInfo
        @next-step="fetchBookingInfoResult"
        @back-step="step--"
      />
    </div>

  </div>
</template>
<script>
import LoadingPage from '@/components/pages/loadingPage.vue';
import SelectDate from '@/components/pages/tvmBooking/selectDate/selectDate.vue';
import SelectTickets from '@/components/pages/tvmBooking/selectTickets/selectTickets.vue';
import SelectShipTime from '@/components/pages/tvmBooking/selectShipTime/selectShipTime.vue';
import FillBookingInfo from '@/components/pages/tvmBooking/fillBookingInfo/fillBookingInfo.vue';
import { apiPostBookingTickets } from '@/apis/booking.js';

export default {
  components: {
    LoadingPage,
    SelectDate,
    SelectTickets,
    SelectShipTime,
    FillBookingInfo,
  },
  watch: {
    step: function() {
        if ( this.step === 0 )  {
            this.$router.push('/');
        }
    }
  },
  data() {
      return {
          step: 1,
          pageData: {
              selectDate: {},
              selectTickets: {},
              selectShipTime: {},
              bookingInfo: {},
              bookingResult: {}
          },
      }
  },
  methods: {
      fetchDateSelectResult( result ) {
          this.pageData.selectDate = result;
          this.step++;
      },
      fetchTicketSelectResult( result ) {
          this.pageData.selectTickets = result;
          this.step++;
      },
      fetchShipTimeSelectResult( result ) {
          this.pageData.selectShipTime = result;
          this.step++;
      },
      fetchBookingInfoResult( result ) {
          this.pageData.bookingInfo = result;
          this.sendBookingRequest();
      },
      async sendBookingRequest() {
          this.step = 99;
          let requestData = {
            route_code: "DL",
            departure_date: (this.pageData.selectDate.departureDate + ' ' + this.pageData.selectShipTime.departure),
            return_date: (this.pageData.selectDate.returnDate + ' ' + this.pageData.selectShipTime.return),
            name: this.pageData.bookingInfo.name,
            id_number: this.pageData.bookingInfo.idNumber,
            phone: this.pageData.bookingInfo.phone,
            adult: this.pageData.selectTickets.adultTickets,
            children: this.pageData.selectTickets.childrenTickets,
            is_passport: this.pageData.bookingInfo.isPassport,
          };
          
          let result = await apiPostBookingTickets(requestData);
          if ( result.status === 200 ) {
              this.pageData.bookingResult = result.data;
              this.$router.push( { 
                  path:"/order/" 
                  + this.pageData.bookingResult.order_number + "/" 
                  + this.pageData.bookingInfo.phone
              } );
          }
          else {
              console.log(result);
          }

      }
  }
}
</script>
<style scope>

</style>
