<template>
  <div>
    <div class="upper-min-height">
      <Header :title="pageStatus.title" />
      <div class="row my-4 py-4">
        <div class="col-10 main-panel mx-auto fs-default">
          <div class="text-start text-blue fw-bold">
            <div v-if="!data.paid">
              本訂單<span class="text-red">尚未付款</span>
            </div>
            <div v-else-if="data.paid">
              本訂單<span class="text-red">付款完成</span>
              ，目前已勾選<span class="text-red mx-2">{{ selectCount }}</span>筆船票
            </div>
            <div class="d-flex justify-content-between">
              <div v-if="!data.paid">
                請先進行付款動作，點選「立即付款」
              </div>
              <div v-else-if="data.paid">
                選取想領取的票卷後，請按「下一步」
              </div>
              <div>
                訂單編號 <span class="text-red">{{ order_number }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-between my-4">
            <div class="tvm-panel">
              <div
                class="header d-flex align-items-center justify-content-start"
              >
                <div class="text fw-bold col-11 mx-auto text-start d-flex">
                  <div class="t-240px d-flex align-items-center">
                      <CustomCheckbox 
                       :disabled="!data.paid"
                       :border="true"
                       :boolean="pageStatus.selectAll"
                       @checked="checkAll"
                      /> 
                      <div class="mx-4 text-nowrap">
                        全選
                      </div> 
                  </div>
                  <div class="t-200px text-nowrap">票別</div>
                  <div class="t-500px text-nowrap">去程日期</div>
                  <div class="t-450px text-nowrap">回程日期</div>
                  <div class="t-450px text-end text-nowrap">出發地/抵達地</div>
                </div>
              </div>
              <div class="body py-4 fs-45px">
                <div 
                 class="fw-bold col-11 mx-auto text-start d-flex align-items-center py-4"
                 v-for="(item, index) in pageStatus.faresArray"
                 :key="index"
                 :class="{ 't-row-bottom-border': index != pageStatus.faresArray.length-1}"
                >
                  <div class="t-240px d-flex align-items-center">
                      <CustomCheckbox 
                       :disabled="!data.paid"
                       :border="true"
                       :boolean="item.is_selected"
                       :emitData="index.toString()"
                       @checked="checkedTicket"
                      /> 
                  </div>
                  <div class="t-200px">{{ item.type==='adult'?'全票':'半票' }}</div>
                  <div class="t-500px">{{ item.schedule.departure.date }} {{ item.schedule.departure.time }}</div>
                  <div class="t-450px">{{ item.schedule.return.date }} {{ item.schedule.return.time }}</div>
                  <div class="t-450px text-end">東港 ↔ 小琉球</div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!data.paid">
        <BottomBar
        :type="'three-btn'"
        :btns="bottomBtns_notpaid"
        @back-homepage="$router.push('/')"
        @order-detail="orderDetail()"
        @pay-now="payNow()"
        />
    </div>
    <div v-if="data.paid">
        <BottomBar
        :type="'two-btn'"
        :btns="bottomBtns_paid"
        @back-homepage="$router.push('/')"
        @next-step="nextStep()"
        />
    </div>
    
    
  </div>
</template>
<script>
import Header from "@/components/layout-components/tvm/tvmHeader.vue";
import BottomBar from "@/components/layout-components/tvm/tvmBottomBar.vue";
import CustomCheckbox from "@/components/basic-components/custom-checkbox.vue";

export default {
  props: {
    data: Object,
    order_number: String,
    info: String,
  },
  components: {
    Header,
    BottomBar,
    CustomCheckbox,
  },
  watch: {
    selectCount: function() {
        this.checkedWatch(); 
    }
  },
  data() {
    return {
      pageStatus: {
        title: "取票列表",
        faresArray: [],
        selectAll: false,
      },
      bottomBtns_notpaid: [
        {
          name: "取消取票",
          action: "back-homepage",
          isMain: false,
        },
        {
          name: "查看明細",
          action: "order-detail",
          isMain: false,
        },
        {
          name: "立即付款",
          action: "pay-now",
          isMain: true,
        },
      ],
      bottomBtns_paid: [
        {
          name: "取消取票",
          action: "back-homepage",
          isMain: false,
        },
        {
          name: "下一步",
          action: "next-step",
          isMain: true,
        },
      ],
    };
  },
  computed: {
    ticketCount() {
      let count = 0 ;
      for ( let i = 0 ; i < this.pageStatus.faresArray.length ; i++ ) {
        count += this.pageStatus.faresArray[i].is_round_trip? 2 : 1;
      }
      return count;
    },
    selectCount() {
        let count = 0 ;
        for ( let i = 0 ; i < this.pageStatus.faresArray.length ; i++ ) {
            if ( this.pageStatus.faresArray[i].is_selected )
                count += this.pageStatus.faresArray[i].is_selected;
        }
        return count;
    },
  },
  methods: {
    checkedTicket( index ) {
        this.pageStatus.faresArray[index].is_selected = !this.pageStatus.faresArray[index].is_selected;
    },
    checkAll() {
        this.pageStatus.selectAll = !this.pageStatus.selectAll;
        for ( let i = 0 ; i < this.pageStatus.faresArray.length ; i++ ) {
            this.pageStatus.faresArray[i].is_selected = this.pageStatus.selectAll;
        }
    },
    checkedWatch() {
        if ( this.selectCount === this.pageStatus.faresArray.length )
            this.pageStatus.selectAll = true;
        else if ( this.selectCount !== this.pageStatus.faresArray.length )
            this.pageStatus.selectAll = false;
    },
    pushFaresArray() {
      this.pageStatus.faresArray = [];
      for (let index = 0; index < this.data.fares.length; index++) {
        for (let i = 0; i < this.data.fares[index].remain; i++) {
          this.pageStatus.faresArray.push({
            id: this.data.fares[index].id,
            name: this.data.fares[index].name,
            is_round_trip: this.data.fares[index].is_round_trip,
            type: this.data.fares[index].type,
            price: this.data.fares[index].price,
            schedule: {
              departure: this.data.schedule.DL,
              return: this.data.schedule.LD,
            },
            is_selected: false,
          });
        }
      }
    },
    init() {
      this.pushFaresArray();
    },
    orderDetail() {
      this.$router.push( { 
        path:"/order/" 
        + this.order_number + "/" 
        + this.info
       } );
    },
    payNow() {
      this.$router.push( { 
        path:"/order/" 
        + this.order_number + "/" 
        + this.info + "?paynow=true"
       } ); 
    },
    nextStep() {
      if ( this.selectCount > 0 ) {

        // emit出 勾選了幾張全票/半票 ; 
        let fares = {
          adult: 0,
          children: 0,
        };
        for ( let i = 0 ; i < this.pageStatus.faresArray.length ; i++ ) {
          if ( this.pageStatus.faresArray[i].is_selected ) {
            if ( this.pageStatus.faresArray[i].type === 'adult' )
              fares.adult++;
            else if ( this.pageStatus.faresArray[i].type === 'children' )
              fares.children++;
          }
        }

        this.$emit( 'next-step', fares );
      }
    },
    numberWithCommas(num) {
      if (this.isNotUndefined(num))
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return "";
    },
    isNotUndefined(val) {
      // console.log( val, typeof val === 'undefined' )
      if (typeof val === "undefined") {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scope>

</style>
