<template>
  <div>
    <Header :title="'付款中'" />

    <div class="row my-4 py-4">
        <div class="col-10 main-panel mx-auto">
            <!-- <iframe 
            id="id_iframe"
            :src="redirect_url" class="iframe-style"
            @load="iframeLoad()"
            >
            </iframe> -->
            <!-- <div class="py-4" >
              <div class="py-4"></div>
              <div class="spinner-border text-primary" role="status" style="height:200px; width:200px;">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="py-4"></div>
            </div> -->
        </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/layout-components/tvm/tvmHeader.vue";


export default {
  props: {
    redirect_url: String,
  },
  components: {
    Header,
  },
  data() {
      return {
          loadTime: 0,
      }
  },
  methods: {
    iframeLoad() {
        this.loadTime++;
        // console.log(this.loadTime);
        if ( this.loadTime > 1 ) {
            this.$emit('pay-redirect');
        }
    }
  },
  mounted() {
    window.location.replace(this.redirect_url);
  }

};
</script>
<style scope>
.iframe-style {
    width: 550px;
    height: 600px;
}
</style>
