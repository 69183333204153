import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Booking from '../views/tvm-Booking.vue'
import OrderInfo from '../views/tvm-OrderInfo.vue'
import Taking from '../views/tvm-Taking.vue'
import PayRedirect from '../views/tvm-Pay-redirect.vue'

import getEnv from '@/utils/env.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/booking',
    name: 'Booking',
    component: Booking,
  },
  {
    path: '/taking',
    name: 'Taking',
    component: Taking,
  },
  {
    path: '/order/:order_number/:info',
    name: 'OrderInfo',
    component: OrderInfo,
  },
  {
    path: '/pay-redirect/:order_number/:info',
    name: 'Pay-redirect',
    component: PayRedirect,
  },
  {
    path: '/download',
    redirect: function(){
      window.location.href = getEnv('VUE_APP_ONLINE_TICKET_URL') + '/download';
    },
  },
  {
    path: '/*',
    redirect: '/'
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
