<template>
  <div>
    <div class="upper-min-height">
      <Header :title="pageStatus.title" />
      <div class="row my-4 py-4">
        <div class="col-10 main-panel mx-auto fs-default">
          <div class="text-start text-blue fw-bold d-flex justify-content-start">
            <div>
              輸入訂票人資訊，輸入完請確認是否無誤，並按「確認訂票」
            </div>
            <!-- <button v-on:click="doScan()">TestDoScan</button> -->
            <!-- <button v-on:click="getRecoginzeResult()">TestGetRecoData</button> -->
          </div>
          <div class="col-12 d-flex justify-content-between my-4">
            <div class="tvm-panel">
              <div
                class="header d-flex align-items-center justify-content-start"
              >
                <div class="text fw-bold col-11 mx-auto text-start d-flex justify-content-between">
                  <div class="text-title d-flex justify-content-between">
                      訂票人資訊
                  </div>
                  <div class="mx-4">
                    <span class="text-red">*</span>為必填
                  </div>
                </div>
              </div>
              <div class="body py-4">
                <div
                  class="col-11 mx-auto text-start my-4 d-flex pb-4 justify-content-between"
                >
                  <div
                    class="d-flex align-items-center col-6 justify-content-between"
                  >
                    <div class="text-title d-flex justify-content-start">
                      訂票人姓名
                    </div>
                    <div class="mx-4">
                      <input
                        type="text"
                        class="input-text px-3 pt-2"
                        :class="{ 'notice' : pageStatus.notices[0] !== undefined && pageStatus.notices[0].desc !== 'success' }"
                        :placeholder="
                          ( pageStatus.notices[0] !== undefined && pageStatus.notices[0].desc !== 'success' ) ? 
                          ( pageStatus.notices[0].desc === 'null' ? '訂票人姓名為必填':'' ) : '請輸入訂票人姓名'
                        "
                        v-model="results.name"
                      />
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center col-6 justify-content-end"
                  >
                    <div class="text-title-right text-end mx-4">
                      <div>
                        出生日期
                      </div>
                      <div class="fs-32px">
                        (1901-01-01)
                      </div>
                    </div>
                    <div class="mx-4">
                      <input
                        type="text"
                        class="input-text px-3 pt-2"
                        :class="{ 'notice' : pageStatus.notices[2] !== undefined && pageStatus.notices[2].desc !== 'success'  && results.birthday === '' }"
                        :placeholder="
                          ( pageStatus.notices[2] !== undefined && pageStatus.notices[2].desc !== 'success' ) ? 
                          ( ( pageStatus.notices[2].desc === 'null' ) ? '出生日期為必填':'出生日期不符合規則' ) : '請輸入出生日期'
                        "
                        v-model="results.birthday"
                        v-on:keyup="dateKeyUp()"
                        v-on:keyup.delete="dateKeyUpDelete()"
                        maxlength="10"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-11 mx-auto text-start my-4 d-flex">
                  <div
                    class="d-flex align-items-center col-6 justify-content-between"
                  >
                    <div class="text-title">
                      <div>
                        身分證字號<span class="text-red">*</span>
                      </div>
                      <div class="fs-32px">
                        (護照號碼請勾選)
                      </div>
                    </div>
                    <div class="mx-4">
                      <input
                        type="text"
                        class="input-text px-3 pt-2"
                        :class="{ 'notice' : pageStatus.notices[1] !== undefined && pageStatus.notices[1].desc !== 'success' }"
                        :placeholder="
                          ( pageStatus.notices[1] !== undefined && pageStatus.notices[1].desc !== 'success' ) ? 
                          ( pageStatus.notices[1].desc === 'null' ? 
                            (results.isPassport ? '護照號碼' : '身分證字號') + '為必填' : (results.isPassport ? '護照號碼' : '身分證字號') + '不符合規則' ) 
                            : ( '請輸入' + (results.isPassport ? '護照號碼' : '身分證字號')
                          )
                        "
                        v-model="results.idNumber"
                        v-on:keyup="results.idNumber=results.idNumber.replace(/[\W]/g,'')"
                      />
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center col-6 justify-content-end"
                  >
                    <div class="text-title-right d-flex justify-content-end">
                      行動電話<span class="text-red">*</span>
                    </div>
                    <div class="mx-4">
                      <input
                        type="text"
                        class="input-text px-3 pt-2"
                        :class="{ 'notice' : pageStatus.notices[3] !== undefined && pageStatus.notices[3].desc !== 'success' }"
                        :placeholder="
                          ( pageStatus.notices[3] !== undefined && pageStatus.notices[3].desc !== 'success' ) ? 
                          ( pageStatus.notices[3].desc === 'null' ? 
                            '行動電話為必填' : '行動電話不符合規則' ) 
                            : '請輸入行動電話'
                        "
                        v-model="results.phone"
                        v-on:keyup="results.phone=results.phone.replace(/\D/g,'')"
                        v-on:onafterpaste="results.phone=results.phone.replace(/\D/g,'')"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-11 mx-auto text-start my-4 d-flex">
                  <div
                    class="d-flex align-items-center col-6 justify-content-between"
                  >
                    <div
                      class="text-title d-flex justify-content-start"
                    ></div>
                    <div class="mx-4 d-flex align-items-center input-checkbox">
                      <div>
                        <CustomCheckbox 
                         :border="true" 
                         :boolean="results.isPassport"
                         @checked="results.isPassport = !results.isPassport"
                        />
                      </div>
                      <div class="mx-4">
                        護照號碼
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center col-6 justify-content-end"
                  >
                    <div class="mx-4">
                      <button 
                       v-if="pageStatus.ocrIsSetup"
                       class="btn-functional"
                       data-bs-toggle="modal" data-bs-target="#ocrModal"
                       >掃描證件自動輸入</button>
                    </div>
                  </div>

                </div>
                <div class="col-11 mx-auto text-start d-flex pb-4">
                    <div class="pt-4 d-flex justify-content-center">
                      <div class="px-4">
                        <CustomCheckbox
                        :boolean="results.acceptCheckbox"
                        :border="true"
                        :disabled="false"
                        @checked="results.acceptCheckbox=!results.acceptCheckbox"
                        />
                      </div>
                      <div class="checkbox-describe text-start pt-2 w-100">
                        我已明確了解誠實設計線上訂票交易之
                        <span class="term-url" data-bs-toggle="modal" data-bs-target="#serviceModal">
                          服務條款
                        </span>
                        及
                        <span class="term-url" data-bs-toggle="modal" data-bs-target="#privacyModal">
                          顧客個人隱私政策
                        </span>
                        事項內容，並且同意遵守所有規定及提供所需之個人資料。<span class="text-red">*</span><br>
                        <span
                         class="text-red fs-32px" 
                         v-if="pageStatus.notices[4] !== undefined && pageStatus.notices[4].desc !== 'success'"
                        >
                          購票前請先勾選同意以上條款
                        </span>
                      </div>
                      
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TermModal 
     :target_id="'serviceModal'"
     :content_type="'service'"
    />
    <TermModal 
     :target_id="'privacyModal'"
     :content_type="'privacy'"
    />
    <OCRModal
     :target_id="'ocrModal'"
     @emit-recognize="setInput"
    />
    <BottomBar
      :type="'three-btn'"
      :btns="bottomBtns"
      @back-homepage="$router.push('/')"
      @back-step="$emit('back-step', 1)"
      @next-step="nextStep()"
    />
  </div>
</template>
<script>
import Header from "@/components/layout-components/tvm/tvmHeader.vue";
import BottomBar from "@/components/layout-components/tvm/tvmBottomBar.vue";
import CustomCheckbox from "@/components/basic-components/custom-checkbox.vue";
import TermModal from '@/components/basic-components/term-modal/term-modal.vue'
import ocrWebSocket from '@/utils/ocr-websocket.js' ;
import OCRModal from "@/components/basic-components/ocr-modal/ocr-modal.vue";

export default {
  components: {
    Header,
    BottomBar,
    CustomCheckbox,
    TermModal,
    OCRModal,
  },
  data() {
    return {
      pageStatus: {
        title: "輸入訂票人資訊",
        notices: [],
        ocrIsSetup: false,
      },
      results: {
        name: "",
        idNumber: "",
        birthday: "",
        phone: "",
        isPassport: false,
        acceptCheckbox: false,
      },
      bottomBtns: [
        {
          name: "取消購票",
          action: "back-homepage",
          isMain: false,
        },
        {
          name: "返回",
          action: "back-step",
          isMain: false,
        },
        {
          name: "下一步",
          action: "next-step",
          isMain: true,
        },
      ],
      ocrData: {
        ocrDeviceName: "",
      }
    };
  },
  methods: {
    dateKeyUp() {
      
      if (/[^\d/-]/g.test(this.results.birthday)) 
        this.results.birthday = this.results.birthday.replace(/[^\d/]/g,'')
        
      if ( this.results.birthday.length === 4 ) {
        this.results.birthday += '-';
      }
      else if ( this.results.birthday.length === 7 ) {
        this.results.birthday += '-';
      }
    },
    dateKeyUpDelete() {
      if ( this.results.birthday.length === 5 ) {
        this.results.birthday = this.results.birthday.substring(0,3);
      }
      else if ( this.results.birthday.length === 8 ) {
        this.results.birthday = this.results.birthday.substring(0,6);
      }
    },
    nextStep() {
      if (this.validateForm()) {
        this.$emit("next-step", this.results);
      }
    },
    scrollResult(data) {
      this.results.birthday = data;
      this.pageStatus.isShowingDateSelect = false;
    },
    getCurrentYear() {
      return parseInt(new Date(this.results.birthday).getFullYear());
    },
    getCurrentMonth() {
      return parseInt(new Date(this.results.birthday).getMonth() + 1);
    },
    getCurrentDate() {
      return parseInt(new Date(this.results.birthday).getDate());
    },
    validateForm() {
      this.pageStatus.notices = [];
      let hasError = false;

      // if (this.results.name === "") {
      //   this.pageStatus.notices.push({
      //     title: "name",
      //     desc: "null",
      //   });
      //   hasError = true;
      // }
      // else {
        this.pageStatus.notices.push({
          title: "name",
          desc: "success",
        });
      // }

      if (this.results.idNumber === "") {
        this.pageStatus.notices.push({
          title: "id",
          desc: "null",
        });
        hasError = true;
      } 
      else if ( !this.results.isPassport && !this.validateIDNumber(this.results.idNumber)) {
        this.pageStatus.notices.push({
          title: "id",
          desc: "non-legal",
        });
        this.results.idNumber = "";
        hasError = true;
      }
      else if ( this.results.isPassport && !this.validatePassport(this.results.idNumber)) {
        this.pageStatus.notices.push({
          title: "id",
          desc: "non-legal",
        });
        this.results.idNumber = "";
        hasError = true;
      }
      else {
        this.pageStatus.notices.push({
          title: "id",
          desc: "success",
        });
      }

      // if (this.results.birthday === "") {
      //   this.pageStatus.notices.push({
      //     title: "birthday",
      //     desc: "null",
      //   });
      //   hasError = true;
      // }
      if ( this.results.birthday !== "" && 
         ( !this.validateDate(this.results.birthday) 
         || this.results.birthday > this.getToday() 
         || this.results.birthday.length < 10 ) ) {
        this.pageStatus.notices.push({
          title: "birthday",
          desc: "non-legal",
        });
        this.results.birthday = "";
        hasError = true;
      }
      else {
        this.pageStatus.notices.push({
          title: "birthday",
          desc: "success",
        });
      }

      if (this.results.phone === "") {
        this.pageStatus.notices.push({
          title: "phone",
          desc: "null",
        });
        hasError = true;
      } else if (!this.validatePhoneNumber(this.results.phone)) {
        this.pageStatus.notices.push({
          title: "phone",
          desc: "non-legal",
        });
        this.results.phone = "";
        hasError = true;
      }
      else {
        this.pageStatus.notices.push({
          title: "phone",
          desc: "success",
        });
      }

      if (this.results.acceptCheckbox === false) {
        this.pageStatus.notices.push({
          title: "accept",
          desc: "non-legal",
        });
        hasError = true;
      }
      else {
        this.pageStatus.notices.push({
          title: "accept",
          desc: "success",
        });
      }


      if (hasError) {
        return false;
      } else return true;
    },
    // execNoticeMessages() {
    //   // 0 => name, 1 => id, 2 => birthday, 3 => phone ;
    // },
    async init() {
      await this.setScanDevice() ;
    },
    async setScanDevice() {
      console.log('setScanDevice');
      let deviceNameSocket = await ocrWebSocket.getDeviceList('127.0.0.1') ;
      let vm = this ;
      deviceNameSocket.onmessage = function(e) {
        console.log('socketData(DeviceName):', e.data);
        vm.ocrData.ocrDeviceName = e.data.split(' ')[1] ;
        vm.pageStatus.ocrIsSetup = true ;
      }
    },
    setInput(recognizeData) {
      this.results.name = recognizeData.name ;
      this.results.idNumber = recognizeData.idNumber ;
      this.results.birthday = recognizeData.birthday ;
      this.results.phone = recognizeData.phone ;
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>
<style scope>

</style>
