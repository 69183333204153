<template>
    <div 
     class="custom-checkbox d-flex justify-content-center align-items-center"
     :class="{ 
         'has-border': border,
         'disabled': disabled,
     }"
     v-on:click="clickChecked()"
    >
        <img 
         src="~@/assets/images/tvm-assets/1920_icon_red_checked.svg" 
         v-if="boolean"
        />
    </div>
</template>
<script>
export default {
    props: {
        boolean: Boolean,
        disabled: Boolean,
        border: Boolean,
        emitData: String
    },
    methods: {
        clickChecked() {
            if ( !this.disabled ) {
                this.$emit( 'checked', this.emitData );
            }
        }
    },
}
</script>