<template>
  <div>
    <div class="upper-min-height">
      <Header
        :title="
          pageStatus.isDeparture ? 
          '選擇去程船班時間 (東港 → 小琉球)' : 
          '選擇回程船班時間 (小琉球 → 東港)'
        "
      />
      <div class="row my-4 py-4">
        <div class="col-10 main-panel mx-auto fs-default">
          <div class="py-4" v-if="pageStatus.shipList.length > 0">
            <div class="text-start text-blue fw-bold my-4">
              <span>{{
                pageStatus.isDeparture ? "去程日期" : "回程日期"
              }}</span>
              <span>
                {{
                  pageStatus.isDeparture
                    ? departure_date + " 星期" + getWeekday(departure_date)[1]
                    : return_date + " 星期" + getWeekday(return_date)[1]
                }}
              </span>
            </div>
            <div class="col-12 my-4 row">
              <ShipTimeBtn
                v-for="(item, index) in pageStatus.shipList"
                :key="index"
                :time="item.data.time"
                :isSelecting="item.isSelected"
                :isDisabled="item.disable"
                :isFull="item.isFull"
                :isSpecial="item.isSpecial"
                @select-time="timeSelect"
              />
            </div>
          </div>
          <div class="py-4" v-else>
            <div class="py-4"></div>
            <div
              class="spinner-border text-primary"
              role="status"
              style="height:200px; width:200px;"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="py-4"></div>
          </div>
        </div>
      </div>
    </div>

    <BottomBar
      :type="'three-btn'"
      :btns="bottomBtns"
      @back-homepage="$router.push('/')"
      @back-step="backStep()"
      @next-step="nextStep()"
    />
  </div>
</template>
<script>
import Header from "@/components/layout-components/tvm/tvmHeader.vue";
import BottomBar from "@/components/layout-components/tvm/tvmBottomBar.vue";
import ShipTimeBtn from "./components/shipTimeBtn.vue";
import { apiGetSailings } from "@/apis/booking.js";

export default {
  components: {
    Header,
    BottomBar,
    ShipTimeBtn,
  },
  props: {
    departure_date: String,
    return_date: String,
    ticketCount: Number,
  },
  data() {
    return {
      pageStatus: {
        shipList: [],
        isDeparture: true,
      },
      selecting: {
        departure: {},
        return: {},
      },
      bottomBtns: [
        {
          name: "取消購票",
          action: "back-homepage",
          isMain: false,
        },
        {
          name: "返回",
          action: "back-step",
          isMain: false,
        },
        {
          name: "下一步",
          action: "next-step",
          isMain: true,
        },
      ],
    };
  },
  computed: {
    nowSelected() {
      for (let i = 0; i < this.pageStatus.shipList.length; i++) {
        if (this.pageStatus.shipList[i].isSelected)
          return this.pageStatus.shipList[i].data.time;
      }
      return "";
    },
  },
  methods: {
    init() {
      this.pageStatus.isDeparture = true;
      this.getShipList();
    },
    getShipList() {
      if (this.pageStatus.isDeparture) this.getShip(this.departure_date, "DL");
      else if (!this.pageStatus.isDeparture)
        this.getShip(this.return_date, "LD");
    },
    async getShip(date, routeCode) {
      // api 取得 航班時間;
      let data = {
        date: date,
        route_code: routeCode,
      };
      this.pageStatus.shipList = []; // 先清空原有的船班
      let searchResult = await apiGetSailings(data);
      if (searchResult.status === 200) {
        for (let i = 0; i < searchResult.data.length; i++) {
          let oneRow = {
            data: searchResult.data[i],
            disable: false,
            isSpecial: false,
            isFull: false,
            isSelected: false,
          };
          oneRow.disable = !this.checkTime(date, oneRow.data.time);
          if ( !this.pageStatus.isDeparture && 
                date === this.departure_date && 
                oneRow.data.time < this.selecting.departure ) {
            oneRow.disable = true;
          }
          if (oneRow.data.booking + this.ticketCount >= oneRow.data.seat) {
            oneRow.disable = true;
            oneRow.isFull = true;
          }
          if ( oneRow.data.type === 'special' 
            || oneRow.data.type === 'speical' ) { // 'speical' 是錯誤拼，等api端有做修正再移除此判斷 ;
            oneRow.isSpecial = true;
          }
          
          this.pageStatus.shipList.push(oneRow);
        }
      } else {
        console.log(searchResult);
      }
    },
    checkTime(date, time) {
      // 檢查輸入的時間是否過期(超過現在時間) ;
      if (date > this.getToday()) {
        return true;
      } else if (date === this.getToday() && time > this.getNowTime()) {
        return true;
      }
      return false;
    },
    timeSelect(emitData) {
      this.select(emitData.time);
    },
    select(time) {
      for (let i = 0; i < this.pageStatus.shipList.length; i++) {
        if (time === this.pageStatus.shipList[i].data.time) {
          this.pageStatus.shipList[i].isSelected = true;
        } else {
          this.pageStatus.shipList[i].isSelected = false;
        }
      }
    },
    nextStep() {
      if (this.pageStatus.isDeparture && this.nowSelected !== "") {
        this.selecting.departure = this.nowSelected;
        this.pageStatus.isDeparture = false;
        this.getShipList();
      } else if (!this.pageStatus.isDeparture && this.nowSelected !== "") {
        this.selecting.return = this.nowSelected;
        this.$emit("next-step", this.selecting);
      }
    },
    backStep() {
      if (this.pageStatus.isDeparture) {
        this.$emit("back-step", 1);
      } else if (!this.pageStatus.isDeparture) {
        this.selecting.departure = "";
        this.selecting.return = "";
        this.pageStatus.isDeparture = true;
        this.getShipList();
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scope>

</style>
