<template>
  <div class="route-info">
    <span class="from fw-bold">{{from}}</span>
    <span class="d-flex align-items-center mx-4 px-4">
      <div class="arrow_left"></div>
      <div class="arrow_right"></div>
    </span>
    <span class="to fw-bold">{{to}}</span>
  </div>
</template>
<script>
export default {
    props: {
        from: String,
        to: String
    }
}
</script>
<style scope>
.route-info {
  height: 8vh;
}
.route-info .from {
  color: #003e92;
  font-family: Noto-Sans CJK TC;
  font-size: 70px;
  font-weight: bold;
}
.route-info .to {
  color: #1a1311;
  font-size: 70px;
  font-weight: bold;
}
.arrow_right {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 20px solid #1a1311;
  font-size: 0;
  line-height: 0;
  transform: rotate(90deg);
}
.arrow_left {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 20px solid #1a1311;
  font-size: 0;
  line-height: 0;
  transform: rotate(270deg);
}
</style>