<template>
    <div 
     class="modal fade" 
     :id="target_id"
     data-bs-keyboard="false" tabindex="-1" 
     aria-labelledby="staticBackdropLabel" 
     aria-hidden="true">
        <div class="modal-dialog term modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <ServiceContent 
                v-if="content_type==='service'"
                />
                <PrivacyContent 
                v-if="content_type==='privacy'"
                />
                <div class="modal-footer justify-content-center py-4">
                    <button type="button" class="btn btn-modal-close fw-bold" data-bs-dismiss="modal">關閉</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ServiceContent from './components/service_content.vue';
import PrivacyContent from './components/privacy_content.vue';

export default {
    props: {
        target_id: String,
        content_type: String,
    },
    components: {
        ServiceContent,
        PrivacyContent
    }
}
</script>
