var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"upper-min-height"},[_c('Header',{attrs:{"title":_vm.pageStatus.title}}),_c('div',{staticClass:"row my-4 py-4 fs-default"},[_c('div',{staticClass:"col-10 main-panel mx-auto"},[_c('div',{staticClass:"text-start text-blue fw-bold"},[_vm._v(" 選擇去程日期 ")]),(_vm.pageStatus.departure_DateList.length > 0)?_c('div',{staticClass:"col-12 d-flex justify-content-start my-4"},_vm._l((_vm.takeArray(
              _vm.pageStatus.departure_DateList,
              0,
              4
            )),function(item,index){return _c('DateBtn',{key:index,attrs:{"date":item.date,"isSelecting":item.isSelected,"isDeparture":true},on:{"select-date":_vm.dateSelect}})}),1):_vm._e(),(_vm.pageStatus.departure_DateList.length > 4)?_c('div',{staticClass:"col-12 d-flex justify-content-start my-4"},_vm._l((_vm.takeArray(
              _vm.pageStatus.departure_DateList,
              4,
              8
            )),function(item,index){return _c('DateBtn',{key:index,attrs:{"date":item.date,"isSelecting":item.isSelected,"isDeparture":true},on:{"select-date":_vm.dateSelect}})}),1):_vm._e(),_c('div',{staticClass:"text-start text-blue fw-bold"},[_vm._v(" 選擇回程日期 ")]),(_vm.pageStatus.return_DateList.length > 0)?_c('div',{staticClass:"col-12 d-flex justify-content-start my-4"},_vm._l((_vm.takeArray(
              _vm.pageStatus.return_DateList,
              0,
              4
            )),function(item,index){return _c('DateBtn',{key:index,attrs:{"date":item.date,"isSelecting":item.isSelected,"isDeparture":false},on:{"select-date":_vm.dateSelect}})}),1):_vm._e(),(_vm.pageStatus.return_DateList.length > 4)?_c('div',{staticClass:"col-12 d-flex justify-content-start my-4"},_vm._l((_vm.takeArray(
              _vm.pageStatus.return_DateList,
              4,
              8
            )),function(item,index){return _c('DateBtn',{key:index,attrs:{"date":item.date,"isSelecting":item.isSelected,"isDeparture":false},on:{"select-date":_vm.dateSelect}})}),1):_vm._e()])])],1),_c('BottomBar',{attrs:{"type":'three-btn',"btns":_vm.bottomBtns},on:{"back-homepage":function($event){return _vm.$router.push('/')},"back-step":function($event){return _vm.$emit('back-step', 1)},"next-step":function($event){return _vm.nextStep()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }