<template>
  <div>
    <div class="upper-min-height">
      <Header :title="pageStatus.title" />
      <div class="row my-4 py-4 fs-default">
        <div class="col-10 main-panel mx-auto">
          <div class="py-4">
            <div class="text-start text-blue fw-bold my-4">
              全票人數
            </div>
            <div class="col-12 d-flex justify-content-between my-4">
              <NumberBtn
                v-for="(item, index) in pageStatus.adult_NumList"
                :key="index"
                :num="item.num"
                :isAdult="true"
                :isSelecting="item.isSelected"
                @select-num="numSelect"
              />
            </div>
          </div>

          <div class="py-4">
            <div class="text-start text-blue fw-bold my-4">
              半票人數
            </div>
            <div class="col-12 d-flex justify-content-between my-4">
              <NumberBtn
                v-for="(item, index) in pageStatus.children_NumList"
                :key="index"
                :num="item.num"
                :isAdult="false"
                :isSelecting="item.isSelected"
                @select-num="numSelect"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <BottomBar
      :type="'three-btn'"
      :btns="bottomBtns"
      @back-homepage="$router.push('/')"
      @back-step="$emit('back-step', 1)"
      @next-step="nextStep()"
    />
  </div>
</template>
<script>
import Header from "@/components/layout-components/tvm/tvmHeader.vue";
import BottomBar from "@/components/layout-components/tvm/tvmBottomBar.vue";
import NumberBtn from "./components/numberBtn.vue";

export default {
  components: {
    Header,
    BottomBar,
    NumberBtn,
  },
  data() {
    return {
      pageStatus: {
        title: "選擇船票數量",
        adult_NumList: [],
        children_NumList: [],
      },
      selecting: {
        adultTickets: 0,
        childrenTickets: 0,
      },
      bottomBtns: [
        {
          name: "取消購票",
          action: "back-homepage",
          isMain: false,
        },
        {
          name: "返回",
          action: "back-step",
          isMain: false,
        },
        {
          name: "下一步",
          action: "next-step",
          isMain: true,
        },
      ],
    };
  },
  methods: {
    init() {
      this.pageStatus.adult_NumList = this.createNum(0);
      this.pageStatus.children_NumList = this.createNum(0);
      this.numSelect({ num: 0, isAdult: true });
      this.numSelect({ num: 0, isAdult: false });
    },
    createNum(num) {
      let array = [];
      let numWalker = num;
      for (let i = 0; i < 7; i++) {
        if (i === 0 && num !== 0) {
          array.push({
            date: "←更少",
            isSelected: false,
          });
        } else if (i === 9) {
          array.push({
            date: "更多→",
            isSelected: false,
          });
        } else {
          array.push({
            num: numWalker,
            isSelected: false,
          });
          numWalker++;
        }
      }
      return array;
    },
    numSelect(emitData) {
      if (emitData.isAdult) {
        this.select(emitData.num, true);
      } else if (!emitData.isAdult) {
        this.select(emitData.num, false);
      }
    },
    select(num, isAdult) {
      if (isAdult) {
        for (let i = 0; i < this.pageStatus.adult_NumList.length; i++) {
          if (num === this.pageStatus.adult_NumList[i].num) {
            this.pageStatus.adult_NumList[i].isSelected = true;
            this.selecting.adultTickets = num;
          } else {
            this.pageStatus.adult_NumList[i].isSelected = false;
          }
        }
      } else if (!isAdult) {
        for (let i = 0; i < this.pageStatus.children_NumList.length; i++) {
          if (num === this.pageStatus.children_NumList[i].num) {
            this.pageStatus.children_NumList[i].isSelected = true;
            this.selecting.childrenTickets = num;
          } else {
            this.pageStatus.children_NumList[i].isSelected = false;
          }
        }
      }
    },
    nextStep() {
      if (
        this.selecting.adultTickets !== 0 ||
        this.selecting.childrenTickets !== 0
      ) {
        this.$emit("next-step", this.selecting);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scope>

</style>
