<template>
  <div>
    <div class="upper-min-height">
      <Header :title="pageStatus.title" />
      <div class="row my-4 py-4">
        <div class="col-10 main-panel mx-auto fs-default">
          <div class="text-start text-blue fw-bold d-flex justify-content-between">
            <div>
              請確認所有資訊是否無誤，確認後即可付款取票
            </div>
            <div>
              訂單編號 <span class="text-red">{{ order_number }}</span>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-between my-4">
            <div class="tvm-panel">
              <div
                class="header d-flex align-items-center justify-content-start"
              >
                <div
                  class="text fw-bold col-11 mx-auto text-start d-flex justify-content-between"
                >
                  <span>票卷資訊</span>
                  <!-- <span>訂票日期 2088-01-01 (星期一)</span> -->
                </div>
              </div>
              <div class="body py-4 col-11 mx-auto text-start d-flex justify-content-between">
                <div class="col-6 fs-default">
                  去程日期 <span class="text-color000000">{{ data.schedule.DL.date }} {{ getWeekday(data.schedule.DL.date) }} {{ data.schedule.DL.time }}</span>
                  <ArrowTwoPosition 
                    class="d-flex align-items-center justify-content-center my-4"
                    :from="'東港'"
                    :to="'小琉球'"
                  />
                  
                </div>
                <div class="col-6 fs-default">
                  回程日期 <span class="text-color000000">{{ data.schedule.LD.date }} {{ getWeekday(data.schedule.LD.date) }} {{ data.schedule.LD.time }}</span>
                  <ArrowTwoPosition 
                    class="d-flex align-items-center justify-content-center my-4"
                    :from="'小琉球'"
                    :to="'東港'"
                  />
                </div>
              </div>
              <div
                class="header d-flex align-items-center justify-content-start"
              >
                <div class="text fw-bold col-11 mx-auto text-start d-flex">
                  <div class="t-660px">編號</div>
                  <div class="t-660px">票別</div>
                  <div class="t-660px">取票張數</div>
                  <div class="t-300px">票價</div>
                </div>
              </div>
              <div class="body py-4">
                <div
                  class="text fw-bold col-11 mx-auto text-start d-flex t-row-bottom-border px-2"
                  v-for="(item, index) in pageStatus.faresArray"
                  :key="index"
                >
                  <div class="t-660px t-row-height d-flex align-items-center fs-default">{{ index + 1 }}</div>
                  <div class="t-660px t-row-height d-flex align-items-center fs-default">
                    {{ item.type === "adult" ? "全票" : "半票" }}
                    {{ item.is_round_trip ? "(來回)" : "(單程)" }}
                  </div>
                  <div class="t-660px t-row-height d-flex align-items-center fs-default">{{ item.is_round_trip ? 2 : 1 }}</div>
                  <div class="t-300px t-row-height d-flex align-items-center fs-default">
                    NT ${{ numberWithCommas(item.price) }}
                  </div>
                </div>
                <div class="d-flex justify-content-between col-11 mx-auto my-4 pt-4 px-2 fs-default fw-bold">
                  <div class="text-color000000">
                    共計
                    <span class="text-red">{{pageStatus.faresArray.length + " "}}</span>人，
                    <span class="text-red">{{ticketCount + " "}}</span>張票
                  </div>
                  <div class="text-color000000">
                    總票價 新台幣 
                    <span class="text-red">NT$ {{numberWithCommas(priceCount)}}</span> 
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BottomBar
      :type="'two-btn'"
      :btns="bottomBtns"
      @pay-later="payLater()"
      @pay-now="payNow()"
    />
  </div>
</template>
<script>
import Header from "@/components/layout-components/tvm/tvmHeader.vue";
import BottomBar from "@/components/layout-components/tvm/tvmBottomBar.vue";
import ArrowTwoPosition from "@/components/basic-components/arrow-two-position.vue";
export default {
  props: {
    data: Object,
    order_number: String,
  },
  components: {
    Header,
    BottomBar,
    ArrowTwoPosition,
  },
  data() {
    return {
      pageStatus: {
        title: "訂單明細",
        faresArray: [],
      },
      bottomBtns: [
        {
          name: "稍後付款",
          action: "pay-later",
          isMain: false,
        },
        {
          name: "立即付款",
          action: "pay-now",
          isMain: true,
        },
      ],
    };
  },
  computed: {
    ticketCount() {
      let count = 0 ;
      for ( let i = 0 ; i < this.pageStatus.faresArray.length ; i++ ) {
        count += this.pageStatus.faresArray[i].is_round_trip? 2 : 1;
      }
      return count;
    },
    priceCount() {
      let count = 0 ;
      for ( let i = 0 ; i < this.pageStatus.faresArray.length ; i++ ) {
        count += this.pageStatus.faresArray[i].price;
      }
      return count;
    }
  },
  methods: {
    pushFaresArray() {
      this.pageStatus.faresArray = [];
      for (let index = 0; index < this.data.fares.length; index++) {
        for (let i = 0; i < this.data.fares[index].count; i++) {
          this.pageStatus.faresArray.push({
            id: this.data.fares[index].id,
            name: this.data.fares[index].name,
            is_round_trip: this.data.fares[index].is_round_trip,
            // count: this.data.fares[index].count,
            // remain: this.data.fares[index].remain,
            type: this.data.fares[index].type,
            price: this.data.fares[index].price,
          });
        }
      }
    },
    init() {
      this.pushFaresArray();
    },
    payLater() {
      this.$emit('pay-later', {
        order_number: this.order_number,
        ticket_count: this.ticketCount,
      })
    },
    payNow() {
      this.$emit( 'pay-now', {
        
      }); 
    },
    numberWithCommas(num) {
      if (this.isNotUndefined(num))
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return "";
    },
    isNotUndefined(val) {
      // console.log( val, typeof val === 'undefined' )
      if (typeof val === "undefined") {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scope>

.tvm-panel .t-660px {
  width: 660px;
}
.tvm-panel .t-300px {
  width: 300px;
}
.tvm-panel .t-row-height {
  height: 100px;
}
.tvm-panel .t-row-bottom-border {
  border-bottom: 1px solid #adabab;
}
.upper-min-height {
  min-height: 920px;
}

</style>
