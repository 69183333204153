<template>
    <button 
     class="col-2 mx-2 btn-select btn-w-date"
     :class="{
         'selecting': isSelecting,
         'functional': isFunctionBtn
     }"
     v-on:click="btnClick(date, isDeparture)"
    >
        <span class="text">
            <span class="text-red" v-if="date===getToday()">今日</span>{{dateStr}}
        </span>
    </button>
</template>
<script>
export default {
    props: {
        date: String,
        isSelecting: Boolean,
        isDeparture: Boolean,
    },
    computed: {
        dateStr() {
            if ( this.date === undefined ) return null ;

            if ( this.date !== '←更早日期' && this.date !== '更晚日期→' ) {
                let dateObject = new Date(this.date);
                let string = this.generateDateString( 
                    dateObject.getFullYear(),
                    dateObject.getMonth()+1,
                    dateObject.getDate(),
                );

                return (dateObject.getMonth()+1>=10) ? 
                    string.substring( 5, string.length ) : 
                    string.substring( 6, string.length ) ;
            }
            else {
                return this.date;
            }
        },
        isFunctionBtn() {
            if ( this.date === '←更早日期' || this.date === '更晚日期→' ) 
              return true;
            else
              return false;
        }
    },
    methods: {
        btnClick(date, isDeparture) {
            this.$emit( "select-date" ,{
                date: date,
                isDeparture: isDeparture,
            });
        },
    },
}
</script>
<style scope>

</style>