<template>
  <div class="row header mx-auto">
    <div class="col-4 d-flex align-items-center justify-content-start">
      <span class="title text-nowrap fs-1">誠實設計線上訂票</span>
    </div>
    <div class="col-4 d-flex align-items-center justify-content-center">
      <span class="title text-nowrap">{{title}}</span>
    </div>
    <div class="col-4 d-flex align-items-center justify-content-end">
      <img v-on:click="$router.push( { path:'/' } )"
        class="logo"
        src="~@/assets/images/tvm-assets/1920_homepage_logo.svg"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
  },
  data() {
    return {
      
    }
  },

}
</script>
<style scope>
.header {
  height: 15vh;
  width: 92vw;
}
.header .title {
  font-size: 60px;
  font-weight: bold;
  color: #ffffff;
}
.header .logo {
  height: 6vh;
  width: 14vw;
}
</style>
