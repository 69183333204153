<template>
  <div>
    <div class="upper-min-height">
      <Header :title="pageStatus.title" />
      <div class="row my-4 fs-default">
        <div class="col-10 main-panel mx-auto">
          <div class="py-3">
            <div class="text-center text-blue fw-bold my-4">
              輸入訂單編號
            </div>
            <input
             type="text"
             class="input-text-width700px mx-2 px-4"
             :class="{ 'notice' : pageStatus.notices[0] !== undefined && pageStatus.notices[0].desc !== 'success' }"
             placeholder="輸入訂單編號"
             v-model="inputs.order_number"
             v-on:keyup="inputs.order_number=inputs.order_number.replace(/[\W]/g,'')"
            />
          </div>
          <div class="py-3">
            <div class="text-center text-blue fw-bold my-4">
              輸入訂票人行動電話
            </div>
            <input
             type="text"
             class="input-text-width700px mx-2 px-4"
             :class="{ 'notice' : pageStatus.notices[1] !== undefined && pageStatus.notices[1].desc !== 'success' }"
             placeholder="輸入訂票人行動電話"
             v-model="inputs.info"
             v-on:keyup="inputs.info=inputs.info.replace(/\D/g,'')"
             v-on:onafterpaste="inputs.info=inputs.info.replace(/\D/g,'')"
            />
          </div>
        </div>
        <div class="py-4 d-flex justify-content-center">
            <div class="px-4">
                <CustomCheckbox
                :boolean="inputs.acceptCheckbox"
                :border="false"
                :disabled="false"
                @checked="inputs.acceptCheckbox=!inputs.acceptCheckbox"
                />
            </div>
            <div class="checkbox-describe text-start pt-2 text-nowrap">
                我已明確了解誠實設計線上訂票交易之<span class="term-url" data-bs-toggle="modal" data-bs-target="#serviceModal">服務條<br>
                款</span>及<span class="term-url" data-bs-toggle="modal" data-bs-target="#privacyModal">顧客個人隱私政策</span>事項內容，並且同意遵<br>
                守所有規定及提供所需之個人資料。<br>
                <span
                   class="text-red fs-32px" 
                   v-if="pageStatus.notices[2] !== undefined && pageStatus.notices[2].desc !== 'success'"
                >
                  取票前請先勾選同意以上條款
                </span>
            </div>
            
        </div>
        <div class="d-flex justify-content-center">
            <span
             class="text-red fs-32px fw-bold" 
             v-if="status !== 'waiting'"
            >
              {{ status === 'order-empty' ? '此訂單已完成取票' : '查無此訂單，請確認輸入資訊正確' }}
            </span>  
        </div>
      </div>
    </div>

    <BottomBar
      :type="'three-btn'"
      :btns="bottomBtns"
      @back-homepage="$router.push('/')"
      @back-step="$emit('back-step', 1)"
      @next-step="nextStep()"
    />
    <TermModal 
     :target_id="'serviceModal'"
     :content_type="'service'"
    />
    <TermModal 
     :target_id="'privacyModal'"
     :content_type="'privacy'"
    />
  </div>
</template>
<script>
import Header from "@/components/layout-components/tvm/tvmHeader.vue";
import BottomBar from "@/components/layout-components/tvm/tvmBottomBar.vue";
import CustomCheckbox from "@/components/basic-components/custom-checkbox.vue";
import { apiGetSearchOrder } from '@/apis/download.js';
import TermModal from '@/components/basic-components/term-modal/term-modal.vue'

export default {
  components: {
    Header,
    BottomBar,
    CustomCheckbox,
    TermModal,
  },
  data() {
    return {
      pageStatus: {
        title: "領取船票",
        notices: [],
      },
      inputs: {
        order_number: "",
        info: "",
        acceptCheckbox: false,
      },
      status: "waiting",
      results: {

      },
      bottomBtns: [
        {
          name: "取消取票",
          action: "back-homepage",
          isMain: false,
        },
        {
          name: "返回",
          action: "back-step",
          isMain: false,
        },
        {
          name: "下一步",
          action: "next-step",
          isMain: true,
        },
      ],
    };
  },
  methods: {
    init() {
        this.detectHasOrder();
    },
    async sendSearchRequest() {
      
      let data = {
        order_number: this.inputs.order_number,
        info: this.inputs.info,
      };
      let results = await apiGetSearchOrder(data);
      if ( results.status === 200 ) {
        console.log("get order data");
        this.results = results.data;
        for ( let i = 0 ; i < this.results.fares.length ; i++ ) {
          if ( this.results.fares[i].remain > 0 ) {
            this.status = 'success';
            return true;
          }
        }
        this.status = 'order-empty';
        this.inputs.order_number = "";
        this.inputs.info = "";
        console.log('票已被取完');
        return false;
        
      } 
      else {
        this.status = 'fail';
        this.inputs.order_number = "";
        this.inputs.info = "";
        console.log("fail");
        return false;
      }
      
    },
    async nextStep() {
      if ( this.validateForm() && await this.sendSearchRequest() ) {
        this.$emit("next-step", { 
            data: this.results,
            order_number: this.inputs.order_number,
            info: this.inputs.info,
        });
      }
    },
    validateForm() {
      this.status = 'waiting'
      this.pageStatus.notices = [];
      let hasError = false;

      if (this.inputs.order_number === "") {
        this.pageStatus.notices.push({
          title: "order_number",
          desc: "null",
        });
        hasError = true;
      } 
      else {
        this.pageStatus.notices.push({
          title: "order_number",
          desc: "success",
        });
      }

      if (this.inputs.info === "") {
        this.pageStatus.notices.push({
          title: "info",
          desc: "null",
        });
        hasError = true;
      } 
      else {
        this.pageStatus.notices.push({
          title: "info",
          desc: "success",
        });
      }

      if (this.inputs.acceptCheckbox === false) {
        this.pageStatus.notices.push({
          title: "accept",
          desc: "non-legal",
        });
        hasError = true;
      }
      else {
        this.pageStatus.notices.push({
          title: "accept",
          desc: "success",
        });
      }

      if (hasError) {
        return false;
      } else return true;
    },
    async detectHasOrder() {
        if ( this.$route.query.order_number !== undefined && this.$route.query.info != undefined ) {
            this.inputs.order_number = this.$route.query.order_number;
            this.inputs.info = this.$route.query.info;
            this.inputs.acceptCheckbox = true;
            this.nextStep();
        }
    }
  },
  mounted() {
    this.init();
  },
};
</script>
<style scope>

</style>
