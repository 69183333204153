const ocrSocketNumber = '17777' ;
const ocrRecoginzeSocketNumber = '17776' ;
const ocrWebSocket = {

    async getDeviceList( ip ) {
        let str = "GetDevicesList" ;
        let socket = new WebSocket("ws://" + ip + ":" + ocrSocketNumber + "/" + str, "webfxscan");
        return socket ;
    },
    sendScanParam( ip, deviceName, otherParams="scanmode=scan&paper-size=none&source=Camera&resolution=300&mode=color&imagefmt=jpg&brightness=0&contrast=0&quality=75&swcrop=false&swdeskew=false&front-eject=false&manual-eject=false&chk_duplexmerge=false&remove-blank-page=false&multifeed-detect=false&denoise=false&remove-blackedges=false&recognize-type=auto&recognize-lang=default" ) {
        let str = "SetParams?device_name=" + deviceName + "&" + otherParams;
        let socket = new WebSocket("ws://" + ip + ":" + ocrSocketNumber + "/" + str, "webfxscan");
        return socket ;
    },
    getRecognizeData( ip, filename, otherParams="recognize-type=auto&fulltext-type=undefined" ) {
        let str = "GetRecognizeData?filename=" + filename + "&" + otherParams ;
        let socket = new WebSocket("ws://" + ip + ":" + ocrRecoginzeSocketNumber + "/" + str, "webfxscan"); 
        return socket ;
    },
    removeFile( ip, filename ) {
        let str = "RmFiles?filename=" + filename ;
        let socket = new WebSocket("ws://" + ip + ":" + ocrSocketNumber + "/" + str, "webfxscan") ;
        return socket ;
    }

}


export default ocrWebSocket